import React from "react";
import { useNavigate } from "react-router-dom";
import { IoMail, IoNotifications, IoChevronForwardOutline } from "react-icons/io5";
import styles from "./NotificationCard.module.css";

const NotificationCard = ({ notification, read }) => {
  const navigate = useNavigate();

  const handlePress = () => {
    read(notification.id);
    if (notification?.redirect?.path === "onboarding") {
      navigate(`/notifications/onboarding/${notification.redirect.id}`);
    } else if (notification?.redirect?.path === "blog") {
      navigate(`/blog/${notification.redirect.id}`);
    } else if (notification?.redirect?.path === "membership") {
      if (notification?.redirect?.id) {
        navigate(`/payment-details/${notification.redirect.id}`);
      } else {
        navigate(`/account/membership`);
      }
    }
  };

  return (
    <div
      className={`${styles.card} ${notification.redirect ? styles.clickable : ""}`}
      onClick={handlePress}
    >
      <div className={styles.content}>
        <div
          className={`${styles.iconContainer} ${notification.type === "alert" ? styles.alertBackground : styles.defaultBackground}`}
        >
          {notification.type === "onboarding" ? (
            <IoMail color={notification.type === "alert" ? "#E02A2A" : "#457789"} size={20} />
          ) : (
            <IoNotifications color={notification.type === "alert" ? "#E02A2A" : "#457789"} size={20} />
          )}
          {!notification?.isRead && <div className={styles.redDot} />}
        </div>
        <div className={styles.textContainer}>
          <p className={styles.title} title={notification.title}>
            {notification.title}
          </p>
          <p className={styles.date}>{notification.created}</p>
        </div>
      </div>
      {notification.redirect && (
        <div>
          <IoChevronForwardOutline color="#777" size={25} style={{ minWidth: 25 }} />
        </div>
      )}
    </div>
  );
};

export default NotificationCard;
