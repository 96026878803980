import React, { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import styles from "./Membership.module.css";
import { toast } from "react-toastify";
import { get } from "../../fetch";
import SubscriptionPlanCard from "../../Components/Account/SubscriptionPlanCard";
import PaymentListItem from "../../Components/Account/PaymentListItem";

const Membership = () => {
  const [payments, setPayments] = useState([]);
  const { setLoading, user, setPageName } = useContext(Context);

  useEffect(() => {
    setPageName("Membership");
    setLoading(true);
    get(`sales/user/${user.uid}`)
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setPayments(updatedResponse);
      })
      .catch(() => {
        toast.error(
          "Sorry! There seems to be a problem. Please come back later."
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SubscriptionPlanCard
          payment={
            payments.find((payment) => payment.isActive) ||
            payments.sort((a, b) => b.created - a.created)[0]
          }
        />
        <h2 className={styles.heading}>Payment history</h2>
        {payments.length > 0 ?
          payments.map((payment) => (
            <PaymentListItem key={payment.id} payment={payment} />
          )) : (
            <span style={{ width: "90%" }}>No payments yet...</span>
          )}
      </div>
    </div>
  );
};

export default Membership;
