import React, { useContext, useState, useEffect } from "react";
import styles from "./HeaderWhite.module.css";
import HeaderLogo from "../assets/cube-whitebg.png";
import { useNavigate, useLocation } from "react-router-dom";
import { FaWhatsapp, FaChevronLeft } from "react-icons/fa";
import Context from "../Context";
import { get } from "../fetch";
import { toast } from "react-toastify";

const HeaderWhite = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [whatsappLink, setWhatsappLink] = useState("");
  const { user, setLoading, pageName, isAuthenticated } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    get("settings/community")
      .then((response) => {
        setWhatsappLink(response.data.whatsappLink);
        setLoading(false);
      })
      .catch(() =>
        toast.error(
          "Sorry! There seems to be a problem. Please come back later."
        )
      );
  }, []);

  const joinWhatsapp = () => {
    const linkCount = user?.links ? Object.keys(user.links).length : 0;
    if (user?.image?.imageId && user?.industry && user?.bio && linkCount)
      window.open(whatsappLink, "_blank");
    else toast.warn("Please complete your profile before you join the WhatsApp group.");
  };

  // Excluded paths for the back button
  const excludedPaths = ["/", "/members", "/notifications", "/account"];

  // Check if the path is /members/:id
  const isMemberIdPath = /^\/members\/[^/]+$/.test(location.pathname);

  return (
    <div
      style={{
        display: !isAuthenticated && location.pathname === "/" && "none",
      }}
      className={`${styles.container} ${
        isAuthenticated ? styles.width : styles.noWidth
      }`}
    >
      <div
        className={`${styles.header} ${
          pageName?.length > 0 ? styles.pageName : ""
        }`}
      >
        {isAuthenticated && pageName?.length === 0 ? (
          <>
            <img
              src={HeaderLogo}
              alt="logo"
              className={styles.logo}
              onClick={() => navigate("/")}
            />
            <div className={styles.whatsapp} onClick={joinWhatsapp}>
              <FaWhatsapp size={30} color="#457789" />
            </div>
          </>
        ) : (
          <>
            {!excludedPaths.includes(location.pathname) &&
              (!isMemberIdPath || isAuthenticated) && (
                <FaChevronLeft
                  className={styles.backBtn}
                  size={20}
                  onClick={() => navigate(-1)}
                />
              )}
            <h3>{pageName}</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderWhite;
