import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Onboarding.module.css";
import { get } from "../../fetch";
import Context from "../../Context";
import { toast } from "react-toastify";

const Onboarding = () => {
  const { id } = useParams();
  const { setPageName, user, setLoading, loading } = useContext(Context);
  const [notification, setNotification] = useState({});

  useEffect(() => {
    setPageName("Onboarding");
    setLoading(true);
    get(`onboarding/${id}`)
      .then((response) => {
        setNotification(response.data);
      })
      .catch(() => toast.error("Sorry! There seems to be a problem. Please come back later."))
      .finally(() => setLoading(false));
  }, []);

  const renderContent = (content) => {
    // Format expiry date
    const expiryDate = new Date(user.expiry).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  
    // Replace {{expiryDate}} with formatted date
    const dateReplaced = content.replace(/{{expiryDate}}/g, expiryDate);
  
    // Replace {{name}} with the user's name
    const nameReplaced = dateReplaced.replace(/{{name}}/g, user.name);
  
    // Replace *text* with bold tags
    const boldReplaced = nameReplaced.replace(/\*(.*?)\*/g, (match, p1) => (
      `<strong>${p1}</strong>`
    ));
  
    const imageReplaced = boldReplaced.replace(/{{image}}/g, () => {
      if (notification.videoUrl) {
        return `<a class="${styles.link}" href="${notification.videoUrl}" target="_blank" rel="noopener noreferrer">
                  <img src="${notification.preview.imageUrl}" alt="Watch Video" class="${styles.image}" />
                </a>`;
      } else {
        return `<img src="${notification.preview.imageUrl}" alt="Image" class="${styles.image}" />`;
      }
    });
  
    // Replace [[link]] with anchor tags
    const linkReplaced = imageReplaced.replace(/\[\[(.*?)\]\]/g, (match, p1) => (
      `<a href="${p1}" class="${styles.link}" target="_blank" rel="noopener noreferrer">${p1}</a>`
    ));
  
    // Replace {{button: text, {{link}}}} with a button element
    const buttonReplaced = linkReplaced.replace(/{{button:\s*(.*?),\s*{{(.*?)}}}}/g, (match, buttonText, buttonLink) => (
      `<a href="${buttonLink}" target="_blank" rel="noopener noreferrer">
         <button class="${styles.button}">${buttonText}</button>
       </a>`
    ));
  
    // Replace \n with <br /> for line breaks
    const lineBreakReplaced = buttonReplaced.replace(/\n/g, "<br />");
  
    return lineBreakReplaced;
  };
  
  

  if (!notification) return null;
  return (
    <div className={styles.container}>
      <div className={styles.pageContent}>
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{notification?.title}</h1>
          {notification?.content && <p
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: renderContent(notification.content) }}
          />}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
