import React from "react";
import { MdLink, MdEdit, MdDelete } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";
import styles from "./LinkCard.module.css";

export default function LinkCard({ link, edit = false, onEdit, onRemove }) {
  const openURL = () => {
    window.open(link.url, "_blank");
  };

  return (
    <div
      onClick={!edit ? openURL : undefined}
      className={`${styles.card} ${edit ? styles.edit : ""}`}
      style={{ cursor: edit ? "default" : "pointer", width: edit ? "90%" : "100%" }}
    >
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <MdLink size={25} color="#00516A" />
        </div>
        <span className={styles.title}>{link.title}</span>
      </div>
      {edit ? (
        <div className={styles.row}>
          <div onClick={onEdit} className={styles.icon}>
            <MdEdit size={20} color="#457789" />
          </div>
          <div onClick={onRemove} className={styles.icon}>
            <MdDelete size={20} color="#E02A2A" />
          </div>
        </div>
      ) : (
        <IoOpenOutline size={22} color="rgb(52, 122, 143)" />
      )}
    </div>
  );
}
