import React, { useContext, useEffect, useState } from "react";
import styles from "./Packages.module.css";
import cube from "../../assets/cube-whitebg.png";
import PlansOverviewCard from "../../Components/Community/PlansOverviewCard";
import Context from "../../Context";
import { get } from "../../fetch";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const Packages = () => {
  const [packs, setPacks] = useState([]);
  const { setLoading, setPageName } = useContext(Context);
  const [selected, setSelected] = useState(0);
  const isMobile = window.innerWidth <= 1276;

  useEffect(() => {
    setPageName("Membership Plans");
    setLoading(true);
    get("settings/packages")
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setPacks(updatedResponse);
      })
      .catch(() =>
        toast.error(
          "Looks like there's a problem. Reload the page and if this happens again, please come back later."
        )
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles.container}>
      {!isMobile && <img className={styles.img} src={cube} alt="packages" />}
      <div className={styles.content}>
        {isMobile ? (
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            modules={[Pagination]}
            onSnapIndexChange={({ realIndex }) => setSelected(realIndex)}
            className={styles.swiperContainer}
          >
            {packs.map((pack) => (
              <SwiperSlide key={pack.pid} className={styles.swiperSlide}>
                <PlansOverviewCard pack={pack} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          packs.map((pack) => (
            <PlansOverviewCard key={pack.pid} pack={pack} />
          ))
        )}
      </div>
    </div>
  );
};

export default Packages;
