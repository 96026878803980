import React, { useContext, useEffect, useState } from "react";
import {
  IoPersonCircleOutline,
  IoLinkOutline,
  IoPeopleOutline,
  IoLockClosedOutline,
  IoLogOutOutline,
  IoDocumentOutline,
} from "react-icons/io5";
import Context from "../../Context";
import ListItem from "../../Components/Account/ListItem";
import styles from "./AccountHome.module.css";
import profile_picture from "../../assets/profile_picture.png";
import { toast } from "react-toastify";

const AccountHome = () => {
  const { user, logout, setPageName, getUserData, setLoading } =
    useContext(Context);
  const [imageSrc, setImageSrc] = useState(
    user?.preview?.dummyUrl || profile_picture
  );

  useEffect(() => {
    setPageName("Account");
    setLoading(true);
    getUserData();
  }, []);

  useEffect(() => {
    if (user?.preview?.imageUrl) {
      const img = new Image();
      img.src = user.preview.imageUrl;
      img.onload = () => setImageSrc(user.preview.imageUrl);
    }
  }, [user?.preview?.imageUrl]);
  const handleReferral = async () => {
    if (user.referralCode) {
      const message = `Salam! 🚀

I'm part of the *Founders Cube* community, and it's been a game-changer for me!

To get *2 FREE months* when you join, insert my referral code:

${user.referralCode}

Let's grow and succeed together—can’t wait to see you inside! 💡`;

      const textarea = document.createElement("textarea");
      textarea.value = message;
      textarea.style.position = "absolute";
      textarea.style.left = "-99999px";
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      toast.success(
        "Message copied to clipboard. You can send this to your friends to get free months!"
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.profilePictureContainer}>
          <img src={imageSrc} alt="Profile" className={styles.profilePicture} />
        </div>

        <ListItem
          icon={IoPersonCircleOutline}
          title="Personal Information"
          to="/edit-profile"
        />
        {user.referralCode ? (
          <ListItem
            icon={IoLinkOutline}
            title="Referral Code"
            onClick={handleReferral}
          />
        ) : (
          <></>
        )}
        {user.type === "member" ? (
          <ListItem
            icon={IoPeopleOutline}
            title="Membership"
            to="/account/membership"
          />
        ) : (
          <></>
        )}
        <ListItem
          icon={IoDocumentOutline}
          title="Code of Conduct"
          to="/terms-and-conditions"
        />
        <ListItem
          icon={IoLockClosedOutline}
          title="Change Password"
          to="/change-password"
        />
        <ListItem
          icon={IoLogOutOutline}
          title="Logout"
          onClick={() => logout()}
          sep={false}
        />
      </div>
    </div>
  );
};

export default AccountHome;
