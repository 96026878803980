import React, { useContext, useEffect, useState } from "react";
import FeaturedBlog from "../Components/Blog/FeaturedBlog";
import BlogCard from "../Components/Blog/BlogCard";
import styles from "./BlogPage.module.css";
import Context from "../Context";
import { get } from "../fetch";
import ProfileCard from "../Components/Blog/ProfileCard";
import { MdAdd, MdArticle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BlogPage = () => {
  const { user, setPageName } = useContext(Context);
  const [blogs, setBlogs] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setPageName("");
    get("blog/published")
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setBlogs(updatedResponse);
      })
      .catch(() =>
        toast.error(
          "Sorry! There seems to be a problem. Please come back later."
        )
      )
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {user.name ? <ProfileCard /> : <></>}
        {blogs?.length > 0 && <FeaturedBlog blog={blogs[0]} />}
        <div className={styles.headingContainer}>
          <h2 className={styles.heading}>Latest Blogs</h2>
          <button className={styles.viewAllBtn} onClick={() => navigate("/all-blogs")}>View All</button>
        </div>
        <div className={styles.blogList}>
          {blogs?.length > 1 ? (
            blogs.slice(1, 4).map((blog) => <BlogCard key={blog.id} blog={blog} />)
          ) : (
            <>
              {!blogs ? (
                <>
                  <BlogCard />
                  <BlogCard />
                </>
              ) : (
                <p style={{ width: "100%" }}>No blogs yet...</p>
              )}
            </>
          )}
        </div>
        {(user?.type === "admin" || user.role === "editor") && (
          <>
            <button
              className={`${styles.postBtn} ${styles.myBlogsBtn}`}
              onClick={() => navigate("/my-blogs")}
            >
              <MdArticle color="#3E6471" size={22} />
            </button>
            <button
              className={styles.postBtn}
              onClick={() => navigate("/post-blog")}
            >
              <MdAdd color="white" size={25} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
