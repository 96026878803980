import React, { useEffect } from "react";
import styles from "./PaymentMethodCard.module.css";
import { IoCashOutline, IoBusinessOutline, IoCardOutline, IoChevronForward } from "react-icons/io5";

const PaymentMethodCard = ({ type, onClick }) => {
  const renderIcon = () => {
    switch (type) {
      case "CLIQ":
        return <IoCashOutline size={30} color="#437689" />;
      case "Bank":
        return <IoBusinessOutline size={30} color="#437689" />;
      case "Card":
        return <IoCardOutline size={30} color="#437689" />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.cardContent}>
        <div className={styles.iconContainer}>{renderIcon()}</div>
        <span className={styles.title}>{type}</span>
      </div>
      <IoChevronForward size={22} color="#777" />
    </div>
  );
};

export default PaymentMethodCard;
