import React, { useState, useEffect, useContext } from "react";
import styles from "./PaymentMethod.module.css";
import { useNavigate, useParams } from "react-router-dom";
import PaymentMethodCard from "../../Components/Account/PaymentMethodCard";
import Context from "../../Context";

const PaymentMethod = () => {
  const { setPageName } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => setPageName("Payment Method"), []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <PaymentMethodCard type="CLIQ" onClick={() => navigate(`/payment/${id}/cliq`)} />
        <PaymentMethodCard type="Bank" onClick={() => navigate(`/payment/${id}/bank`)} />
      </div>
    </div>
  );
};

export default PaymentMethod;
