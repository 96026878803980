import React, { useState, useEffect, useContext } from "react";
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from "react-icons/io5";
import Context from "../../Context";
import styles from "./ProfileCard.module.css";
import { useNavigate } from "react-router-dom";

const ProfileCard = () => {
  const { user, loading } = useContext(Context);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const linkCount = user?.links ? Object.keys(user.links).length : 0;
    if (user?.image?.imageId && user?.industry && user?.bio && linkCount)
      setVisible(false);
    else setVisible(true);
  }, [user]);

  if (loading) return null;

  return (
    <div className={`${styles.container} ${!visible && styles.hidden}`}>
      <h2 className={styles.title}>Hello {user?.name?.split(" ")[0]}!</h2>
      <p className={styles.subtitle}>
        Here are a few steps to complete your profile!
      </p>
      <div className={styles.sep} />
      <div className={styles.step}>
        <IconComponent condition={user.image?.imageId} />
        <p>Upload a profile picture</p>
      </div>
      <div className={styles.step}>
        <IconComponent condition={user.industry} />
        <p>Add the industry you work in</p>
      </div>
      <div className={styles.step}>
        <IconComponent condition={user?.bio} />
        <p>Tell us about yourself</p>
      </div>
      <div className={styles.step}>
        <IconComponent condition={user?.links && Object.keys(user?.links)?.length} />
        <p>Add links to connect with founders</p>
      </div>
      <button className={styles.btn} onClick={() => navigate("/edit-profile")}>
        Edit Profile
      </button>
    </div>
  );
};

const IconComponent = ({ condition }) =>
  condition ? (
    <IoCheckmarkCircle size={25} color="green" className={styles.icon} />
  ) : (
    <IoCheckmarkCircleOutline size={25} color="grey" className={styles.icon} />
  );

export default ProfileCard;
