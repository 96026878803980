import React, { useEffect, useState } from "react";
import { MdVerified, MdOutlineEmail } from "react-icons/md";
import styles from "./UserCard.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { saveAs } from "file-saver";
import { IoBookmarkOutline } from "react-icons/io5";
import profile_picture from "../../assets/profile_picture.png";
import axios from "axios";

export default function UserCard({ member }) {
  const [imageSrc, setImageSrc] = useState(
    member?.preview?.dummyUrl || profile_picture
  );

  const phoneCall = () => {
    window.open(`tel:${member.phone}`);
  };

  const email = () => {
    window.open(`mailto:${member.email}`);
  };

  const addToContacts = async () => {
    const generateVCard = async () => {
      const fullName = member.name || "";
      const [firstName, ...lastNameParts] = fullName.split(" ");
      const lastName = lastNameParts.join(" ");
      const links = member.links || {};

      let imageBase64 = "";

      // Fetch the image and convert to Base64
      if (member?.preview?.imageUrl) {
        try {
          const response = await axios.get(member.preview.imageUrl, {
            responseType: "arraybuffer",
          });
          const buffer = Buffer.from(response.data, "binary");
          imageBase64 = buffer.toString("base64");
        } catch (err) {
          console.error("Error fetching image:", err);
        }
      }

      let content = `
BEGIN:VCARD
VERSION:3.0
N:${lastName};${firstName}
FN:${fullName}
ORG:${member.company || ""}
TEL;TYPE=CELL:${member.phone || ""}
EMAIL;TYPE=WORK:${member.email || ""}
ADR:${member.country || ""}
      `;
      
      // Embed the image in Base64 if it's available
      if (imageBase64) {
        content += `\nPHOTO;ENCODING=BASE64;TYPE=JPEG:${imageBase64}`;
      }

      content += `\n${Object.values(links).map(link => `URL:${link.url}`).join("\n")}\nEND:VCARD`;

      return content.trim();
    };

    try {
      const vCard = await generateVCard();
      const blob = new Blob([vCard], { type: "text/vcard" });
      saveAs(blob, `${member.name || "contact"}.vcf`);
    } catch (err) {
      console.error("Error generating vCard:", err);
    }
  };

  const fullName = member.name || "";
  const nameParts = fullName.split(" ");
  const displayName =
    nameParts.length > 1
      ? nameParts[0] + " " + nameParts[nameParts.length - 1]
      : nameParts[0];

  useEffect(() => {
    if (member?.preview?.imageUrl) {
      const img = new Image();
      img.src = member.preview.imageUrl;
      img.onload = () => setImageSrc(member.preview.imageUrl);
    }
  }, [member]);

  return (
    <div className={styles.card}>
      <div className={styles.cardImageContainer}>
        <img className={styles.img} src={imageSrc} alt="Profile" />
        <div
          onClick={addToContacts}
          className={`${styles.userAction} ${styles.saveContact}`}
        >
          <IoBookmarkOutline style={{ color: "white", fontSize: "25px" }} />
        </div>
        <div className={styles.cardMainInfo}>
          <div className={styles.nameContainer}>
            <span className={styles.name}>
              {displayName}{" "}
              {member.isVerified ? (
                <MdVerified style={{ color: "white", fontSize: "18px" }} />
              ) : (
                <></>
              )}
            </span>
            <br />
            <span className={styles.industry}>{member?.industry}</span>
          </div>
          <div className={styles.userActions}>
            {member.phone ? (
              <div onClick={phoneCall} className={styles.userAction}>
                <FaPhoneAlt style={{ color: "white", fontSize: "18px" }} />
              </div>
            ) : (
              <></>
            )}
            <div onClick={email} className={styles.userAction}>
              <MdOutlineEmail style={{ color: "white", fontSize: "25px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardSecondaryInfo}>
        <div className={styles.secondaryInfoItem}>
          <span className={styles.secondaryInfoTitle}>Location</span>
          <span className={styles.secondaryInfoSubtitle}>
            {member.country || "-"}
          </span>
        </div>
        <div className={styles.verticalSep} />
        <div className={styles.secondaryInfoItem}>
          <span className={styles.secondaryInfoTitle}>Company</span>
          <span className={styles.secondaryInfoSubtitle}>
            {member.company || "-"}
          </span>
        </div>
      </div>
    </div>
  );
}
