import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../fetch";
import Context from "../../Context";
import {
  IoCashOutline,
  IoBusinessOutline,
  IoCardOutline,
  IoImage,
  IoTrashOutline
} from "react-icons/io5";
import styles from "./Pay.module.css";
import { toast } from "react-toastify";
import { uploadImage } from "../../helpers/media";

const Pay = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pack, setPack] = useState({});
  const [accountDetails, setAccountDetails] = useState({});
  const { setLoading, setPageName } = useContext(Context);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const renderIcon = () => {
    switch (type) {
      case "CLIQ":
        return <IoCashOutline size={30} color="#437689" />;
      case "Bank":
        return <IoBusinessOutline size={30} color="#437689" />;
      case "Card":
        return <IoCardOutline size={30} color="#437689" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setLoading(true);
    get(`settings/packages/${id}`)
      .then((r) => {
        setPack(r.data);
        setPageName(`${r.data.name} Plan`);
        get("settings/billing")
          .then((response) => {
            setAccountDetails(response.data);
          })
          .catch(() => {
            toast.error("Could not load billing details. Please try again.");
          });
      })
      .catch(() => {
        toast.error("There seems to be an error, please come back later.");
      })
      .finally(() => setLoading(false));
  }, []);

  const pickImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  const submit = async () => {
    if (!imageFile) {
      toast.error("Please upload an image before submitting.");
      return;
    }

    setLoading(true);
    const image = await uploadImage(imageFile, "sales", true);
    const body = {
      method: type,
      pid: id,
      image,
    };

    try {
      await post("sales", body);
      toast.success(
        "Payment has been sent. You'll be notified as soon as we approve it."
      );
      navigate("/");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error sending payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.main}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={styles.price}>JD {pack?.price}</span>
            <span className={styles.mainDetails}>
              {pack.name} plan fees
            </span>
          </div>
          <div className={styles.listIconContainer}>{renderIcon()}</div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardRow}>
            <span>Name</span>
            <span className={styles.value}>{accountDetails.name}</span>
          </div>
          <div className={styles.cardRow}>
            <span>Bank</span>
            <span className={styles.value}>{accountDetails.bank}</span>
          </div>
          {type === "CLIQ" ? (
            <div className={styles.cardRow}>
              <span>CLIQ</span>
              <span className={styles.value}>{accountDetails.cliq}</span>
            </div>
          ) : (
            <>
              <div className={styles.cardRow}>
                <span>ACC Number</span>
                <span className={styles.value}>{accountDetails.accountNo}</span>
              </div>
              <div className={styles.cardRow}>
                <span>IBAN</span>
                <span className={styles.value}>{accountDetails.iban}</span>
              </div>
            </>
          )}
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={pickImage}
          style={{ display: "none" }}
          id="image-upload"
        />

        {selectedImage && (
          <div
            className={styles.imageContainer}
            onClick={() => {setSelectedImage(null); setSelectedImage(null);}}
          >
            <img
              src={selectedImage}
              alt="screenshot"
              className={styles.imagePreview}
            />
            <IoTrashOutline
              size={15}
              color="white"
              className={styles.trash}
            />
          </div>
        )}

        {!selectedImage ? (
          <label htmlFor="image-upload" className={styles.button}>
            <IoImage size={20} color="white" style={{ marginRight: 8 }} />
            {selectedImage ? "Change Screenshot" : "Upload Screenshot"}
          </label>
        ) : (
          <button className={styles.button} onClick={submit}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default Pay;
