import React, { useEffect, useContext, useState } from "react";
import styles from "./MyBlogs.module.css";
import Context from "../Context";
import BlogCard from "../Components/Blog/BlogCard";
import { get } from "../fetch";
import { toast } from "react-toastify";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const MyBlogs = () => {
  const navigate = useNavigate();
  const { setPageName, setLoading } = useContext(Context);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    setPageName("My Blogs");
    setLoading(true);
    get("blog/writer")
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setBlogs(updatedResponse);
        console.log(updatedResponse);
      })
      .catch(() =>
        toast.error(
          "Sorry! There seems to be a problem. Please come back later."
        )
      )
      .finally(() => setLoading(false));
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {blogs.length ? (
          blogs.map((blog) => <BlogCard showStatus key={blog.id} blog={blog} />)
        ) : (
          <span className={styles.msg}>No blogs yet...</span>
        )}
      </div>
      <button className={styles.postBtn} onClick={() => navigate("/post-blog")}>
        <MdAdd color="white" size={25} />
      </button>
    </div>
  );
};

export default MyBlogs;
