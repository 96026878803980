import React, { useContext, useEffect, useState } from "react";
import styles from "./Member.module.css";
import { useParams } from "react-router-dom";
import ReactLinkify from "react-linkify";

import { get } from "../../fetch";
import Context from "../../Context";
import UserCard from "../../Components/Community/UserCard";
import LinkCard from "../../Components/Community/LinkCard";
import { toast } from "react-toastify";

const Member = () => {
  const { id } = useParams();
  const [member, setMember] = useState({});
  const [load, setLoad] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { setLoading, setPageName } = useContext(Context);

  useEffect(() => {
    const fullName = member.name || "";
    const nameParts = fullName.split(" ");
    const displayName = nameParts.length > 1 ? nameParts[0] + " " + nameParts[nameParts.length - 1] : nameParts[0];

    setPageName(displayName);
  }, [member]);

  useEffect(() => {
    setLoading(true);
    get(`user/${id}`)
      .then((response) => {        
        setMember(response.data);
        setLoad(true);
      })
      .catch(() => {
        toast.error("There seems to be a problem. Pease come back later");
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderBio = () => {
    if (!member?.bio) {
      return "No bio yet...";
    }
  
    const bioText = member.bio;
    const bioWords = bioText.split(" ");
    const truncatedBio = bioWords.slice(0, 40).join(" ");
    const handleLineBreaks = (text) => text.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  
    return (
      <>
        <ReactLinkify
          componentDecorator={(href, text, key) => (
            <a
              href={href}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "your_desired_color" }}
            >
              {text}
            </a>
          )}
        >
          {isExpanded ? handleLineBreaks(bioText + " ") : handleLineBreaks(truncatedBio + "... ")}
        </ReactLinkify>
        {bioWords.length > 40 && (
          <button className={styles.readMoreBtn} onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </>
    );
  };
  

  if (load)
    return (
      <div className={styles.container}>
        <UserCard member={member} />
        <div className={styles.content}>
          <p className={styles.heading}>About Me</p>
          <p className={styles.bio}>
            {renderBio()}
          </p>
          <p style={{ marginBottom: 6, marginTop: 10 }} className={styles.heading}>Links</p>
          {member.links && Object.keys(member.links).length > 0 ? (
          Object.entries(member.links).map(([key, link]) => (
            <LinkCard key={key} link={link} />
          ))) : (
            <p className={styles.bio}>No links yet...</p>
          )}
        </div>
      </div>
    );
  else return <></>;
};

export default Member;
