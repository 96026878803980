import React, { useState, useContext } from "react";
import styles from "./LoginPage.module.css";
import cube from "../../assets/cube-whitebg.png";
import email from "../../assets/Auth/email.svg";
import password from "../../assets/Auth/password.svg";
import AuthText from "../../Components/TextComponents/AuthText";
import { useNavigate } from "react-router-dom";
import Context from "../../Context";

const LoginPage = () => {
  const { auth } = useContext(Context);
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: {
      text: "",
      error: false,
    },
    password: {
      text: "",
      error: false,
    },
  });

  const setEmail = (email) => {
    setData({
      ...data,
      email: {
        text: email,
        error: false,
      },
    });
  };

  const setPassword = (password) => {
    setData({
      ...data,
      password: {
        text: password,
        error: false,
      },
    });
  };

  const login = (e) => {
    e.preventDefault();
    if (data.email.text !== "" && data.password.text !== "") {
      const body = { email: data.email.text, password: data.password.text };
      auth(body);
    } else {
      if (data.email.text === "") {
        setData({
          ...data,
          email: {
            text: "",
            error: true,
          },
        });
      }
      if (data.password.text === "") {
        setData({
          ...data,
          password: {
            text: "",
            error: true,
          },
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.cube} src={cube} alt="cube" />
        <h2 className={styles.title}>Welcome!</h2>
        <span className={styles.msg}>Enter your email and password</span>
        <form onSubmit={login}>
          <AuthText
            type="email"
            placeholder="Email"
            value={data.email.text}
            error={data.email.error}
            onChange={(e) => setEmail(e.target.value)}
            img={email}
          />
          <AuthText
            type="password"
            placeholder="Password"
            value={data.password.text}
            error={data.password.error}
            onChange={(e) => setPassword(e.target.value)}
            img={password}
          />
          <button
            type="button"
            className={styles.forgetBtn}
            onClick={() => navigate("/forget-password")}
          >
            Forgot Password
          </button>
          <button type="submit" className={styles.loginBtn} onClick={login}>
            Login
          </button>
        </form>
        <p className={styles.register}>
          Not a member?{" "}
          <button onClick={() => window.location.href = `https://www.thefounderscube.com/en/plans/`}>Apply now</button>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
