import { useContext, useEffect, useState, CSSProperties } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styles from "./App.module.css";
import HomePage from "./Pages/BlogPage";
import NotificationsPage from "./Pages/Notifications/NotificationsPage";
// import Footer from "./Components/Footer";
import AccountHome from "./Pages/Account/AccountHome";
import EditProfile from "./Pages/Account/EditProfile";
import CommunityMembers from "./Pages/Community/CommunityMembers";
import Member from "./Pages/Community/Member";
import Packages from "./Pages/Community/Packages";
import Application from "./Pages/Auth/Application";
import LoginPage from "./Pages/Auth/LoginPage";
import ScrollToTop from "./Components/ScrollToTop";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Terms from "./Pages/Auth/Terms";
import PaymentMethod from "./Pages/Auth/PaymentMethod";
import Pay from "./Pages/Auth/Pay";

import Context from "./Context";
import { PropagateLoader } from "react-spinners";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import HeaderWhite from "./Components/HeaderWhite";
import ViewBlog from "./Pages/ViewBlog";
import BottomNav from "./Components/BottomNav";
import MyBlogs from "./Pages/MyBlogs";
import NewBlog from "./Pages/NewBlog";
import Onboarding from "./Pages/Notifications/Onboarding";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Membership from "./Pages/Account/Membership";
import PaymentDetails from "./Pages/Account/PaymentDetails";
// import AppBanner from "./Components/AppBanner";
import NotFound from "./Pages/NotFound";
import AllBlogs from "./Pages/AllBlogs";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function App() {
  const { user, loading, setLoading, isAuthenticated } = useContext(Context);
  const [load, setLoad] = useState(false);
  const { pathname } = useLocation();
  const [addHeight, setAddHeight] = useState(true);

  useEffect(() => {
    if (
      pathname === "/account" ||
      pathname === "/change-password" ||
      pathname === "/edit-profile" ||
      pathname === "/community/whatsapp" ||
      (pathname.includes("member") &&
        pathname !== "/community/special-members" &&
        pathname !== "/community/members") ||
      pathname.includes("payment")
    ) {
      setAddHeight(false);
    } else {
      setAddHeight(true);
    }
  }, [pathname]);

  // useEffect(() => {
  //   const path = window.location.pathname;
  //   let id;
  //   if (path.startsWith("/blog/")) {
  //     id = path.split("/blog/")[1];
  //     if (id) window.location.href = `founderscube://blog/${id}`;
  //   } else if (path.startsWith("/members/")) {
  //     id = path.split("/members/")[1];
  //     if (id) window.location.href = `founderscube://member/${id}`;
  //   } else if (path.startsWith("/notifications/onboarding/")) {
  //     id = path.split("/notifications/onboarding/")[1];
  //     if (id) window.location.href = `founderscube://onboarding/${id}`;
  //   } else {
  //     window.location.href = "founderscube://";
  //   }
  // }, []);

  useEffect(() => {
    setTimeout(() => setLoad(true), 600);
  }, []);

  if (load)
    return (
      <>
        <ToastContainer
          style={{
            fontSize: "12px",
            padding: "8px 12px",
            minWidth: "200px",
          }}
          toastStyle={{
            fontSize: "15px",
            padding: "8px 12px",
            minWidth: "200px",
          }}
          hideProgressBar
          draggable
        />
        {loading && (
          <div className={styles.loaderContainer}>
            <PropagateLoader
              color="#000000"
              loading={true}
              cssOverride={override}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <ScrollToTop />
        {/* <AppBanner /> */}
        <HeaderWhite />
        <div
          style={{ minHeight: addHeight ? "calc(100vh - 200px)" : "unset" }}
          className={styles.appContainer}
        >
          {isAuthenticated ? (
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/terms-and-conditions" element={<Terms />} />

              {(user?.type === "member" && !user?.expiry) ||
              user?.expiry - Date.now() <= 0 ? (
                <>
                  <Route path="/" element={<Membership />} />
                  <Route
                    path="/account/membership/membership-plans"
                    element={<Packages />}
                  />
                  <Route
                    path="/payment-details/:id"
                    element={<PaymentDetails />}
                  />
                  <Route path="/payment/:id" element={<PaymentMethod />} />
                  <Route
                    path="/payment/:id/cliq"
                    element={<Pay type="CLIQ" />}
                  />
                  <Route
                    path="/payment/:id/bank"
                    element={<Pay type="Bank" />}
                  />
                  <Route path="/members/:id" element={<Member />} />
                </>
              ) : (
                <>
                  <Route path="*" element={<NotFound />} />
                  <Route path="/" element={<HomePage />} />
                  <Route path="/blog/:id" element={<ViewBlog />} />
                  <Route path="/all-blogs" element={<AllBlogs />} />
                  {(user.type === "admin" || user.role === "editor") && (
                    <>
                      <Route path="/my-blogs" element={<MyBlogs />} />
                      <Route path="/post-blog" element={<NewBlog />} />
                      <Route path="/post-blog/:id" element={<NewBlog />} />
                    </>
                  )}

                  <Route
                    path="/notifications"
                    element={<NotificationsPage />}
                  />
                  <Route
                    path="/notifications/onboarding/:id"
                    element={<Onboarding />}
                  />

                  <Route path="/account" element={<AccountHome />} />
                  <Route path="/edit-profile" element={<EditProfile />} />
                  <Route path="/change-password" element={<ResetPassword />} />
                  <Route
                    path="/reset-password"
                    element={<ResetPassword forget />}
                  />
                  {user.type === "member" ? (
                    <>
                      <Route
                        path="/account/membership"
                        element={<Membership />}
                      />
                      <Route
                        path="/account/membership/membership-plans"
                        element={<Packages />}
                      />
                      <Route
                        path="/payment-details/:id"
                        element={<PaymentDetails />}
                      />
                      <Route path="/payment/:id" element={<PaymentMethod />} />
                      <Route
                        path="/payment/:id/cliq"
                        element={<Pay type="CLIQ" />}
                      />
                      <Route
                        path="/payment/:id/bank"
                        element={<Pay type="Bank" />}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <Route path="/members" element={<CommunityMembers />} />
                  <Route path="/members/:id" element={<Member />} />
                </>
              )}
            </Routes>
          ) : (
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route
                path="/reset-password"
                element={<ResetPassword forget />}
              />
              <Route
                path="/reset-password/new-user"
                element={<ResetPassword newUser />}
              />
              <Route path="/apply" element={<Application />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/members/:id" element={<Member />} />
            </Routes>
          )}
        </div>
        {isAuthenticated && <BottomNav />}
      </>
    );
  else return <></>;
}

export default App;
