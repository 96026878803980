import styled from 'styled-components';

export const CropperDialogContainer = styled.div`
  position: fixed;
  z-index: 1000;
  width: 90%;
  max-width: ${props => props.maxWidth ? props.maxWidth : '500px'};
  background: #fff;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  max-height: 100vh;
`

export const CropperDialogOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0%;
  top: 0%;
`

export const CropperDialogHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
`

export const HeaderEnd = styled.div`
  margin-left: ${props => props.isRTL ? '0px' : 'auto'};
  margin-right: ${props => props.isRTL ? 'auto' : '0px'};
`

export const CropperDialogTitle = styled.div`
  font-size: 17px;
  color: #000;
  font-weight: 700;
`

export const CropperDialogContent = styled.div`
  padding: ${props => props.padding ? props.padding : '10px'};
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;


export const CropperDialogActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const IconButton = styled.div`
  font-size: ${props => props.iconSize ? props.iconSize : '23px'};
  height: ${props => props.iconSize ? props.iconSize : '23px'};
  color: ${props => props.color ? props.color : '#000'};
  cursor: pointer;
  border-radius: 50%;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  opacity: ${props => props.disabled ? '0.5' : '1.0'};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`

export const Divider = styled.div`
  height: 0.8px;
  background: rgba(0, 0, 0, 0.2);
`
