import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Context from "../Context";
// import TextFieldWithDropdown from "./TextFieldWithDropdown";
import styles from "./NewBlog.module.css";
import { get, post, put } from "../fetch";
import Select from "react-select";
import { toast } from "react-toastify";
import { uploadImage } from "../helpers/media";
import CropperDialog from "../Components/CropperDialog";
import Cropper from "react-cropper";
import { FaEyeSlash, FaRocket } from "react-icons/fa";

const NewBlog = () => {
  const { id } = useParams();
  const { user, setLoading, setPageName } = useContext(Context);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [topics, setTopics] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [blog, setBlog] = useState({});
  const [isCropperDialogOpen, setCropperDialogOpen] = useState(false);
  const [cropperData, setCropperData] = useState(null);
  const imgRef = useRef();
  const cropperRef = useRef();

  const searchStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      cursor: "text",
      backgroundColor: "none",
      display: "flex",
      alignItems: "center",
      fontWeight: "500",
      marginTop: -10,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      fontWeight: "500",
      fontSize: 14,
      cursor: "pointer",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150,
      overflowY: "auto",
    }),
  };

  useEffect(() => {
    setPageName(id ? "Edit Blog" : "New Blog");
    get("settings/topics").then((response) => {
      const t = Object.values(response.data);
      setTopics(t);
    });
    if (id) {
      get(`blog/${id}`)
        .then((response) => {
          setBlog({...response.data, ...(user.type === "admin" && { isPublished: response.data.status === "published" })});
          setLoading(false);
        })
        .catch(() =>
          toast.error(
            "Sorry! There seems to be a problem. Please come back later"
          )
        )
        .finally(() => setLoading(false));
    } else {
      setBlog({
        title: "",
        topics: [],
        content: "",
        image: {},
        ...(user.type === "admin" && { isPublished: false })
      });
    }
  }, [id]);

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (files.length === 0) return;

    setSelectedImage(files[0]);
    setCropperDialogOpen(true);
    setIsImageLoaded(false);
  };

  const postBlog = async () => {
    if (
      blog.title.length === 0 ||
      blog.content.length < 300 ||
      blog.topics.length === 0
    ) {
      toast.error(
        "All fields are required. Please make sure you've filled all fields correctly."
      );
    } else if (id) {
      setLoading(true);
      const body = {
        title: blog.title,
        topics: blog.topics,
        content: blog.content,
      };
      let image;
      if (selectedImage) {
        image = await uploadImage(selectedImage, "blog");
        body.image = image;
      }
      if (user.type === "admin") body.isPublished = blog.isPublished;
      put(`blog/${id}`, body)
        .then(() => {
          navigate(-1);
          toast.success("Blog updated");
        })
        .catch((error) =>
          toast.error(
            error?.response?.data?.error ||
              "There seems to be a problem. Pease try again later"
          )
        )
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      if (!selectedImage) {
        toast.error("Please upload an image");
        return;
      }
      const image = await uploadImage(selectedImage, "blog");
      const body = {
        ...blog,
        image
      }
      if (user.type === "admin") body.isPublished = blog.isPublished;
      post("blog", body)
        .then(() => {
          navigate(-1);
          toast.success("Blog posted");
        })
        .catch((error) =>
          toast.error(
            error?.response?.data?.error ||
              "There seems to be a problem. Pease try again later"
          )
        )
        .finally(() => setLoading(false));
    }
  };

  const addTopic = (label) => {
    if (!blog.topics.includes(label)) {
      setBlog({ ...blog, topics: [...blog.topics, label] });
    }
  };

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const onReady = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      // Set an initial zoom to fit the image within the crop box
      cropperRef.current.cropper.zoomTo(1);
    }
  };

  const onCropSubmit = () => {
    if (selectedImage === null) {
      return;
    }
    const cropper = cropperRef.current?.cropper;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();
    setCropperData(dataUrl);
    setCropperDialogOpen(false);
    if (dataUrl !== null) {
      const file = dataURItoBlob(dataUrl);
      setSelectedImage(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.pageContent}>
        <div
          className={styles.imagePicker}
          onClick={() => document.getElementById("fileInput").click()}
          style={{
            backgroundImage:
              !isImageLoaded && blog.preview?.dummyUrl
                ? `url(${blog.preview.dummyUrl})`
                : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {selectedImage || blog.preview?.imageUrl ? (
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : blog.preview.imageUrl
              }
              alt="Selected"
              className={styles.image}
              onLoad={() => setIsImageLoaded(true)} // Set loaded state when image fully loads
            />
          ) : (
            <span className={styles.placeholder}>
              Upload Image from Camera Roll
            </span>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            id="fileInput"
            style={{ display: "none" }}
          />
        </div>

        <input
          className={styles.input}
          style={{ paddingTop: 10, paddingBottom: 10 }}
          type="text"
          placeholder="Title"
          value={blog.title}
          onChange={(e) => setBlog({ ...blog, title: e.target.value })}
        />

        <textarea
          className={styles.content}
          placeholder="This is a new blog! (At least 300 characters)"
          value={blog.content}
          onChange={(e) => setBlog({ ...blog, content: e.target.value })}
        />

        {blog.content && (
          <span
            className={`${styles.characterCount} ${
              blog.content.trim().length < 300 ? styles.warning : ""
            }`}
          >
            {blog.content.trim().length}/300
          </span>
        )}

        <div className={styles.topicsContainer}>
          {blog.topics?.map((topic) => (
            <button
              key={topic}
              className={styles.topic}
              onClick={() =>
                setBlog((prevBlog) => ({
                  ...prevBlog,
                  topics: prevBlog.topics.filter((t) => t !== topic),
                }))
              }
            >
              {topic}
            </button>
          ))}
        </div>
        {user.type === "admin" && (
          <div
            onClick={() => setBlog({ ...blog, isPublished: !blog.isPublished })}
            className={styles.publishButton}
          >
            {blog.isPublished ? (
              <FaEyeSlash size={22} color="red" />
            ) : (
              <FaRocket size={22} color="#437689" />
            )}
            <span>{blog.isPublished ? "Hide" : "Publish"} this blog</span>
          </div>
        )}

        <Select
          className={styles.input}
          placeholder="Add topics..."
          value={null}
          onChange={(e) => addTopic(e.value)}
          options={topics.map((option) => ({
            label: option,
            value: option,
          }))}
          styles={searchStyles}
          isSearchable
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
        />
        <button
          onClick={postBlog}
          style={{ marginTop: isOpen ? 180 : "" }}
          className={styles.submitButton}
        >
          {id ? "Update Blog" : "Post Blog"}
        </button>
      </div>

      <CropperDialog
        isOpen={isCropperDialogOpen}
        setOpen={setCropperDialogOpen}
        maxWidth="600px"
        title={"Crop"}
        persistant
        child={
          <>
            {selectedImage !== null ? (
              <Cropper
                src={URL.createObjectURL(selectedImage)}
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={1}
                aspectRatio={16 / 9}
                guides={false}
                viewMode={1}
                minCropBoxWidth={100}
                minCropBoxHeight={100}
                background={false}
                responsive={true}
                checkOrientation={true}
                ref={cropperRef}
                onReady={onReady}
              />
            ) : (
              <></>
            )}
          </>
        }
        actions={
          <button
            className={styles.submitButton}
            style={{ width: "90%" }}
            onClick={onCropSubmit}
          >
            Save
          </button>
        }
      />
    </div>
  );
};

export default NewBlog;
