import React from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { FaRegQuestionCircle } from "react-icons/fa";
import styles from './ListItem.module.css';

const ListItem = ({ icon, title, to, sep = true, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div className={styles.listItem} onClick={handleClick}>
        <div className={styles.listItemContent}>
          {React.createElement(icon, { size: 24, color: "#777" })}
          <span className={styles.listItemTitle}>{title}</span>
        </div>
        {to && <FaChevronRight size={18} color="#777" />}
      </div>
      {sep && <div className={styles.sep} />}
    </>
  );
};

export default ListItem;
