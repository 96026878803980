import React, { useEffect, useContext, useState } from "react";
import styles from "./MyBlogs.module.css";
import Context from "../Context";
import BlogCard from "../Components/Blog/BlogCard";
import { get } from "../fetch";
import { toast } from "react-toastify";
import Search from "../Components/TextComponents/Search";
import { useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";

const AllBlogs = () => {
  const { setPageName, setLoading, user } = useContext(Context);
  const [blogs, setBlogs] = useState([]);
  const [displayedBlogs, setDisplayedBlogs] = useState([]);
  const [topics, setTopics] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchText) {
      const filteredBlogs = blogs?.filter(
        (blog) =>
          blog?.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
          blog?.content?.toLowerCase().includes(searchText?.toLowerCase())
      );
      setDisplayedBlogs(filteredBlogs);
      setSelectedOption(null);
    } else {
      setDisplayedBlogs(blogs);
    }
  }, [searchText, blogs]);

  useEffect(() => {
    setPageName("All Blogs");
    setLoading(true);
    get("blog/published")
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setBlogs(updatedResponse);
        setDisplayedBlogs(updatedResponse);
      })
      .then(() => {
        get("settings/topics").then((r) => {
          const response = r.data;
          const t = Object.values(response);
          setTopics(["All", ...t]);
        });
      })
      .catch(() =>
        toast.error(
          "Sorry! There seems to be a problem. Please come back later."
        )
      )
      .finally(() => setLoading(false));
  }, []);

  const filterBlogs = () => {
    if (selectedOption === "All") {
      setSelectedOption(null);
      setDisplayedBlogs(blogs);
      return;
    }

    const filteredBlogs = blogs?.filter((blog) =>
      blog?.topics?.includes(selectedOption)
    );
    setDisplayedBlogs(filteredBlogs);
    setSearchText("");
  };

  useEffect(() => {
    if (selectedOption) filterBlogs();
  }, [selectedOption]);

  if (!topics) return <></>;
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {blogs.length ? (
          <>
            <Search
              options={topics}
              searchText={searchText}
              setSearchText={setSearchText}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              filter
            />
            {displayedBlogs.map((blog) => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </>
        ) : (
          <span className={styles.msg}>No blogs yet...</span>
        )}
      </div>
      {(user?.type === "admin" || user.role === "editor") && (
        <button
          className={styles.postBtn}
          onClick={() => navigate("/post-blog")}
        >
          <MdAdd color="white" size={25} />
        </button>
      )}
    </div>
  );
};

export default AllBlogs;
