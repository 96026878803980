import React, { useContext, useEffect } from "react";
import styles from "./Terms.module.css";
import cube from "../../assets/cube-whitebg.png";
import Context from "../../Context";

const Terms = () => {
  const { setPageName, isAuthenticated } = useContext(Context);
  useEffect(() => setPageName(isAuthenticated ? "Code of Conduct" : "Terms & Conditions"), [])
  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${!isAuthenticated && styles.contentPadding}`}>
        <img className={styles.cube} src={cube} alt="cube" />
        <p className={styles.title}>Terms & Conditions</p>
        <p
          className={styles.text}
        >{`This Entrepreneur Community Agreement (the "Agreement") is entered into by and between The Founders Cube (the "Administrator") and the members (the "Members") of the Entrepreneur Community (the "Entrepreneurial Community").`}</p>

        <p className={styles.heading}>1. Purpose of the Community</p>
        <p className={styles.text}>
          The Community aims to foster collaboration, networking, and potential
          business opportunities among its members. By joining, all Members
          agree to abide by the rules outlined herein.
        </p>
        <p className={styles.heading}>2. Membership Eligibility</p>
        <p className={styles.text}>
          To be eligible for membership, individuals must meet the following
          criteria:
          <li>Be actively involved in entrepreneurship or related fields</li>
          <li>
            Provide authentic and verifiable information during the application
            process
          </li>
          <li>Agree to abide by the terms and conditions of this Agreement</li>
        </p>
        <p className={styles.heading}>3. Admission and Screening</p>
        <p className={styles.text}>
          The Administrator will make their best effort to screen potential
          members; however, they are not responsible for any subsequent business
          dealings between members outside the Community.
        </p>
        <p className={styles.heading}>4. Code of Conduct</p>
        <p className={styles.text}>
          All Members must adhere to a strict code of conduct:
          <li>
            Respect and courtesy are mandatory in all interactions within the
            WhatsApp group and any associated events.
          </li>
          <li>
            Discrimination, harassment, hate speech, or any form of abusive
            behavior will not be tolerated
          </li>
          <li>Any form of unsolicited advertising or spamming is prohibited</li>
          <li>
            Confidential information shared within the Community must not be
            disclosed to third parties
          </li>
        </p>
        <p className={styles.heading}>5. Community Activities</p>
        <p className={styles.text}>
          Members may engage in activities such as networking events,
          knowledge-sharing, and collaboration. Any business deals that arise
          from these activities should be conducted fairly and ethically.
        </p>
        <p className={styles.heading}>6. Accountability</p>
        <p className={styles.text}>
          The Administrator reserves the right to remove members who violate
          this Agreement or engage in inappropriate conduct, without notice.
        </p>
        <p className={styles.heading}>7. Dispute Resolution</p>
        <p className={styles.text}>
          In case of disputes between members, the Community and its
          Administrator will not be held responsible. It is recommended that
          members attempt to resolve disputes amicably.
        </p>
        <p className={styles.heading}>8. Disclaimer</p>
        <p className={styles.text}>
          The Administrator and the Community do not endorse or vouch for the
          reliability or trustworthiness of any member. Members engage with each
          other at their own risk.
        </p>
        <p className={styles.heading}>9. Modification of Agreement</p>
        <p className={styles.text}>
          The Administrator may amend this Agreement as needed. Members will be
          notified of any changes.
        </p>
        <p className={styles.heading}>10. Termination</p>
        <p className={styles.text}>
          Members may voluntarily leave the Community at any time. The
          Administrator reserves the right to terminate membership for any
          reason, including violations of this Agreement.
        </p>
        <p className={styles.heading}>11. Final Acknowledgment</p>
        <p className={styles.text}>
          By joining the Entrepreneur Community, all Members acknowledge that
          they have read, understood, and agreed to abide by this Agreement.
        </p>
        <p className={styles.heading}>12. Contact Information</p>
        <p className={`${styles.text} ${styles.lastTest}`}>
          For any inquiries or concerns related to this Agreement, please
          contact the Administrator on +962790095454
        </p>
      </div>
    </div>
  );
};

export default Terms;
