import React, { useContext, useState, useEffect } from "react";
import styles from "./ResetPassword.module.css";
import AuthText from "../../Components/TextComponents/AuthText";
import { post } from "../../fetch";
import Context from "../../Context";
import { useNavigate } from "react-router-dom";
import cube from "../../assets/cube-whitebg.png";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const { setLoading, setPageName } = useContext(Context);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setPageName("Forget Password");
  }, []);

  const submit = (e) => {
    e.preventDefault();
    if (email.length > 0) {
      setLoading(true);
      post("auth/sendResetEmail", { email })
        .then(() => {
          navigate(-1);
          toast.success("Success! Check your email for a reset password link.");
        })
        .catch((error) =>
          toast.error(
            error?.response?.data?.error ||
              "Email not found. Make sure you've entered the correct email."
          )
        )
        .finally(() => setLoading(false));
    } else setError(true);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.content} ${styles.centered}`}>
        <img className={styles.cube} src={cube} alt="cube" />
        <h2 className={styles.title}>Reset Password</h2>
        <p className={styles.msg}>
          Enter your email bellow to recieve a reset password link.
        </p>
        <form onSubmit={submit}>
          <AuthText
            type="email"
            placeholder="Email"
            value={email}
            error={error}
            onChange={(e) => {
              setError(false);
              setEmail(e.target.value);
            }}
          />
          <button
            type="submit"
            style={{ marginTop: 30 }}
            className={styles.loginBtn}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
