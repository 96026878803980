import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PaymentListItem.module.css";
import {
  IoCashOutline,
  IoBusinessOutline,
  IoCardOutline,
  IoGiftOutline,
} from "react-icons/io5";

export default function PaymentListItem({ payment }) {
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (payment.method.toLowerCase()) {
      case "cliq":
        return <IoCashOutline size={30} color={payment.status === "approved" ? "#437689" : "white"} />;
      case "bank":
        return <IoBusinessOutline size={30} color={payment.status === "approved" ? "#437689" : "white"} />;
      case "card":
        return <IoCardOutline size={30} color={payment.status === "approved" ? "#437689" : "white"} />;
      case "gift":
        return <IoGiftOutline size={30} color={payment.status === "approved" ? "#437689" : "white"} />;
      default:
        return null;
    }
  };
  return (
    <div
      className={styles.listItem}
      onClick={() => navigate(`/payment-details/${payment.id}`)}
    >
      <div
        style={{
          backgroundColor:
            payment.status === "pending"
              ? "#ffd32c"
              : payment?.status === "rejected"
              ? "#FF0000cc"
              : "#eee",
        }}
        className={styles.listIconContainer}
      >
        {renderIcon()}
      </div>
      <div className={styles.listItemContent}>
        <p className={styles.listItemDate}>{payment.created}</p>
        <p
          className={styles.listItemDescription}
          title={payment.package.duration}
        >
          {payment.package.name}{" "}
          {payment.package.name.toLowerCase() !== "exclusive reward" && "Plan"}{" "}
          ({payment?.package?.duration} month
          {payment?.package?.duration > 1 && "s"})
        </p>
      </div>
      <p className={styles.listItemPrice}>JD {payment.total}</p>
    </div>
  );
}
