import { IoSearch } from "react-icons/io5";
import styles from "./Search.module.css";
import { useState, useEffect } from "react";
import { IoFunnelOutline } from 'react-icons/io5';

const Search = ({
  searchText,
  setSearchText,
  setSelectedOption,
  selectedOption = "All",
  options,
  filter,
}) => {
  const [searchCategoryText, setSearchCategoryText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  useEffect(() => setDisplayedOptions(options), [options]);
  useEffect(() => {
    const allOptions = options || [];
    const filteredOptions = allOptions.filter((option) =>
      option?.toLowerCase().includes(searchCategoryText?.toLowerCase())
    );
    setDisplayedOptions(filteredOptions);
  }, [searchCategoryText]);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        <IoSearch style={{ marginLeft: 10 }} size={20} />
        <input
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          className={styles.input}
          placeholder="Search"
        />
      </div>
      {filter ? (
        <>
          <button onClick={() => setIsOpen(!isOpen)} className={styles.button}>
            {selectedOption || <IoFunnelOutline size={25} color="#fff" />}
          </button>
          {isOpen && (
            <div className={styles.selectContainer}>
              <input
                placeholder="Search categories..."
                value={searchCategoryText}
                onChange={(e) => setSearchCategoryText(e.target.value)}
                className={styles.categoryInput}
              />
              <ul className={styles.select}>
                {displayedOptions.map((option) => (
                  <li
                    key={option}
                    onClick={() => handleOptionClick(option)}
                    className={option === selectedOption ? styles.selected : ""}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Search;
