import { useContext } from "react";
import Context from "../Context";
import styles from "./NotFound.module.css";

const NotFound = () => {
  const { loading } = useContext(Context);
  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.content}>
          <h1 className={styles.title}>404</h1>
          <h2 className={styles.subtitle}>Page Not Found</h2>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotFound;
