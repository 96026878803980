import React, { useContext, useEffect, useState } from "react";
import styles from "./CommunityMembers.module.css";
import Search from "../../Components/TextComponents/Search";
import MembersCard from "../../Components/Community/MembersCard";
import { MdVerified } from "react-icons/md";
import { IoFunnel } from "react-icons/io5"; // Funnel Icon
import { get } from "../../fetch";
import Context from "../../Context";

const CommunityMembers = ({ special = false }) => {
  const [members, setMembers] = useState([]);
  const [shownMembers, setShownMembers] = useState([]);
  const [verifiedMembers, setVerifiedMembers] = useState(false);
  const { setLoading, setPageName } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [searchCategoryText, setSearchCategoryText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    toggleVerifiedMembers(false);
  };

  useEffect(() => setPageName("Members"), []);

  useEffect(() => {
    const options = [
      "All",
      "Accounting",
      "AI Apps",
      "Architecture",
      "AR/VR in Architecture",
      "Artificial Intelligence",
      "Automotive",
      "Beauty Tech",
      "Biotechnology",
      "Blockchain",
      "Childcare Service",
      "Children Services",
      "Clean Energy",
      "Clean Water Technology",
      "Clothing & Apparel",
      "Coaching",
      "Construction",
      "Consulting",
      "Consumer Electronics",
      "Copywriting",
      "Cybersecurity",
      "Dentistry",
      "Desserts",
      "Digital Marketing",
      "Digital Publishing",
      "E-commerce",
      "Education",
      "Edutainment",
      "Elderly Care",
      "Electric Vehicles",
      "Entertainment",
      "Environmental Services",
      "Event Planning",
      "Eye Wear",
      "Fashion and Apparel",
      "Finance and Banking",
      "Financial Technology (FinTech)",
      "Fitness and Wellness",
      "Food and Beverage",
      "Footwear",
      "Gaming",
      "Health and Beauty",
      "Healthcare",
      "Home Automation",
      "Home Improvement",
      "Home Appliances",
      "Homewares",
      "Hospitality",
      "Human Resources",
      "Interior Design",
      "Internet of Things (IoT)",
      "Landscaping",
      "Language Learning",
      "Legal Services",
      "Logistics and Supply Chain",
      "Manufacturing",
      "Marketing and Advertising",
      "Medical Devices",
      "Media Production",
      "Nanotechnology",
      "Nonprofit and Social Services",
      "Online Learning",
      "Outdoor Recreation",
      "Personal Development",
      "Personal Finance",
      "Pet Care",
      "Pharmaceuticals",
      "Photography",
      "Professional Services",
      "Real Estate",
      "Renewable Energy",
      "Robotics",
      "Social Impact",
      "Social Media",
      "Software Development",
      "Sports and Fitness",
      "Subscription Services",
      "Sustainable Agriculture",
      "Sustainable Fashion",
      "Technology Services",
      "Telecommunications",
      "Telemedicine",
      "Travel and Tourism",
      "Virtual Reality",
      "Video Production",
      "Wearable Technology",
      "Web Development",
    ];
    const memberIndustries = new Set(
      members
        .filter((member) => member?.industry)
        .map((member) => member?.industry)
    );
    const includedOptions = options.filter(
      (option) => option === "All" || memberIndustries.has(option)
    );
    if (searchCategoryText.length > 0) {
      const filteredOptions = includedOptions.filter((option) =>
        option.toLowerCase().includes(searchCategoryText?.toLowerCase())
      );
      setDisplayedOptions(filteredOptions);
    } else {
      setDisplayedOptions(includedOptions);
    }
  }, [searchCategoryText, members]);

  useEffect(() => {
    setLoading(true);
    const fetchMembers = async () => {
      try {
        const r = await get(special ? "special-members" : "user/");
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setMembers(updatedResponse);
        // filterMembers(
        //   updatedResponse,
        //   searchText,
        //   selectedOption,
        //   verifiedMembers
        // );
      } catch (e) {
        // handle error
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [special]);

  useEffect(() => {
    const filteredMembers = members.filter((member) => {
      const isAllOption = selectedOption.toLowerCase() === "all";
      const matchesIndustry =
        isAllOption ||
        (member?.industry &&
          member?.industry?.toLowerCase() === selectedOption.toLowerCase());
      const matchesSearchText =
        member?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        (member?.industry &&
          member?.industry?.toLowerCase().includes(searchText.toLowerCase())) ||
        (member?.bio &&
          member?.bio?.toLowerCase().includes(searchText.toLowerCase()));

      const matchesVerifiedStatus = verifiedMembers ? member.isVerified : true;

      return matchesIndustry && matchesSearchText && matchesVerifiedStatus;
    });

    setShownMembers(filteredMembers);
  }, [searchText, selectedOption, verifiedMembers, members]);

  const toggleVerifiedMembers = (value) => {
    setVerifiedMembers(value);
  };

  useEffect(() => {
    setIsOpen(false)
  }, [shownMembers]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Search searchText={searchText} setSearchText={setSearchText} />
        {isOpen && (
          <div className={styles.selectContainer}>
            <input
              placeholder="Search categories..."
              value={searchCategoryText}
              onChange={(e) => setSearchCategoryText(e.target.value)}
              className={styles.categoryInput}
            />
            <ul className={styles.select}>
              {displayedOptions.map((option) => (
                <li
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className={option === selectedOption ? styles.selected : ""}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className={styles.buttonContainer}>
          <button
            className={
              selectedOption !== "All"
                ? `${styles.toggleButton} ${styles.activeButton}`
                : styles.filterButton
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            <IoFunnel style={{ color: "black", fontSize: "16px" }} />
            {selectedOption !== "All" && (
              <span style={{ marginLeft: 3 }}>{selectedOption}</span>
            )}
          </button>
          <button
            className={`${styles.toggleButton} ${
              !verifiedMembers && selectedOption === "All"
                ? styles.activeButton
                : ""
            }`}
            onClick={() => {
              toggleVerifiedMembers(false);
              setSelectedOption("All");
            }}
          >
            All
          </button>
          <button
            className={`${styles.toggleButton} ${
              verifiedMembers ? styles.activeButton : ""
            }`}
            onClick={() => {
              toggleVerifiedMembers(true);
              setSelectedOption("All");
            }}
          >
            Verified
            <MdVerified
              style={{ color: "black", fontSize: "16px", marginLeft: 5 }}
            />
          </button>
        </div>

        {shownMembers.map((member) => (
          <MembersCard key={member.id} member={member} />
        ))}
      </div>
    </div>
  );
};

export default CommunityMembers;
