import React, { useState } from "react";
import styles from "./TextEditProfile.module.css";
import ReactPhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { MdLock } from "react-icons/md";

const TextEditProfile = ({
  type,
  value,
  onChange,
  placeholder,
  select = false,
  long = false,
  options,
  required = true,
  longPlaceholder = "",
  length = 0,
  onError,
  onValid,
  readOnly = false,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleBlur = () => {
    setIsTouched(true);
    let error = "";

    if (required && (!value || value.trim() === "")) {
      error = "This field is required.";
    } else if (value.length < length) {
      error = `This field requires at least ${length} characters.`;
    } else {
      switch (type) {
        case "email":
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            error = "Please enter a valid email address.";
          }
          break;
        case "phone":
          if (!/^\+\d{1,3}\s?\d{4,14}$/.test(value)) {
            error = "Please enter a valid phone number.";
          }
          break;
        case "url":
          if (
            !/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.[a-z]{2,}(\.[a-z]{2,})?/.test(
              value
            )
          ) {
            error = "Please enter a valid URL.";
          }
          break;
        default:
          error = "";
      }
    }

    setErrorMessage(error);

    if (error) {
      onError && onError(placeholder, error);
    } else {
      onValid && onValid(placeholder);
    }
  };

  const searchStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      padding: 0,
      backgroundColor: "none",
      height: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "black"
        : state.isFocused || state.isActive
        ? "grey"
        : null,
      color: state.isSelected ? "white" : null,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: value && value.length > 0 ? "black" : "grey",
    }),
  };

  return (
    <div className={styles.container}>
      <div style={{  display: "flex", alignItems: "center", marginBottom: 7 }}>
        {readOnly && <MdLock size={15} style={{ marginRight: 2 }} />}
        <label className={styles.label}>
          {placeholder}
          {required ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
      </div>
      {!select && (
        <>
          {type === "phone" ? (
            <ReactPhoneInput
              placeholder="+962 7 9999 9999"
              value={value}
              className={styles.input}
              onChange={(value) => {
                onChange(value);
                if (isTouched) handleBlur();
              }}
              onBlur={handleBlur}
              defaultCountry="JO"
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                boxSizing: "border-box",
              }}
            />
          ) : (
            <>
              {long ? (
                <textarea
                  className={styles.textarea}
                  required={required}
                  type={type}
                  value={value}
                  placeholder={longPlaceholder || ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (isTouched) handleBlur();
                  }}
                  onBlur={handleBlur}
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxSizing: "border-box",
                  }}
                />
              ) : (
                <input
                  className={styles.input}
                  required={required}
                  type={type}
                  value={value}
                  readOnly={readOnly}
                  placeholder={longPlaceholder || ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (isTouched) handleBlur();
                  }}
                  onBlur={handleBlur}
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxSizing: "border-box",
                    backgroundColor: readOnly ? "#eee" : "unset",
                    borderWidth: readOnly ? 0 : 1,
                  }}
                />
              )}
            </>
          )}
          {errorMessage && (
            <span style={{ color: "red", fontSize: 12, marginTop: 3 }}>
              {errorMessage}
            </span>
          )}
        </>
      )}
      {select && (
        <Select
          className={styles.input}
          value={{ label: value || placeholder, value: value }}
          onChange={(selectedOption) => {
            onChange(selectedOption.value);
            if (isTouched) handleBlur();
          }}
          options={options.map((option) => ({ label: option, value: option }))}
          styles={searchStyles}
          isSearchable
        />
      )}
    </div>
  );
};

export default TextEditProfile;
