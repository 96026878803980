import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./EditProfile.module.css";
import TextEditProfile from "../../Components/TextComponents/TextEditProfile";
import Context from "../../Context";
import { put } from "../../fetch";
import { businessCategories } from "../../businessCategories";
import profile_picture from "../../assets/profile_picture.png";
import Cropper from "react-cropper";
// import Cropper from "react-easy-crop";
import "cropperjs/dist/cropper.css";
import CropperDialog from "../../Components/CropperDialog";
import { countriesArr } from "../Auth/countries";
import LinkCard from "../../Components/Community/LinkCard";
import { MdAdd } from "react-icons/md";
import Popup from "../../Components/Popup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadImage } from "../../helpers/media";

const EditProfile = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [linkToEdit, setLinkToEdit] = useState({});
  const [selectedProfilePic, setSelectedProfilePic] = useState(null);
  const [cropperData, setCropperData] = useState(null);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const { user, setLoading, setPageName, getUserData } = useContext(Context);
  const [displayedProfilePic, setDisplayedProfilePic] = useState(
    user?.preview?.dummyUrl || profile_picture
  );
  const [profile, setProfile] = useState({
    name: user.name || "",
    preview: user.preview || "",
    bio: user.bio || "",
    phone: user.phone || "",
    country: user.country || "",
    company: user.company || "",
    industry: user.industry || "",
    links: user.links || {},
  });
  useEffect(() => setPageName("Personal Information"), []);

  const imgRef = useRef();

  const [isCropperDialogOpen, setCropperDialogOpen] = useState(false);
  const cropperRef = useRef();

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  const onCropSubmit = () => {
    if (selectedProfilePic === null) {
      return;
    }
    const cropper = cropperRef.current?.cropper;
    const dataUrl = cropper.getCroppedCanvas().toDataURL();
    setCropperData(dataUrl);
    setCropperDialogOpen(false);
    if (dataUrl !== null) {
      const file = dataURItoBlob(dataUrl);
      setSelectedProfilePic(file);
    }
  };

  const onProfilePicChange = (e) => {
    let files = e.target.files;
    if (files.length === 0) {
      return;
    }

    setSelectedProfilePic(files[0]);
    setCropperDialogOpen(true);
  };

  useEffect(() => {
    if (selectedProfilePic !== null) {
      if (cropperData !== null) {
        setDisplayedProfilePic(cropperData);
      } else {
        setDisplayedProfilePic(URL.createObjectURL(selectedProfilePic));
      }
    } else if (profile.preview?.imageUrl) {
      const img = new Image();
      img.src = user.preview.imageUrl;
      img.onload = () => setDisplayedProfilePic(user.preview.imageUrl);
    } else {
      // setDisplayedProfilePic(profile_picture);
    }
  }, [selectedProfilePic, profile]);

  useEffect(() => {
    const countryData = countriesArr.map((country) => country.name.common);
    setCountries(countryData.sort((a, b) => a.localeCompare(b)));
  }, []);

  const addLink = (link, key = null) => {
    setOpenPopup(false);
    setProfile((prevProfile) => ({
      ...prevProfile,
      links: {
        ...prevProfile.links,
        ...(key ? { [key]: link } : { [`link-${Date.now()}`]: link }),
      },
    }));
  };
  const removeLink = (key) => {
    setProfile((prevProfile) => {
      const updatedLinks = { ...prevProfile.links };
      delete updatedLinks[key];
      return { ...prevProfile, links: updatedLinks };
    });
  };

  const submit = async () => {
    if (
      profile.name.length &&
      profile.bio.length >= 300 &&
      profile.phone.length &&
      profile.country.length &&
      profile.industry.length
    ) {
      if (!user.image?.imageId && !selectedProfilePic) {
        toast.error("Please upload a profile picture.");
        return;
      }

      setLoading(true);
      const body = {
        name: profile.name,
        bio: profile.bio,
        phone: profile.phone,
        country: profile.country,
        company: profile.company,
        industry: profile.industry,
        links: profile.links,
      };

      let image;
      if (selectedProfilePic) {
        image = await uploadImage(selectedProfilePic, "user");
        body.image = image;
      }

      put(`user/${user.uid}`, body)
        .then(() => toast.success("Profile updated successfully!"))
        .catch((error) =>
          toast.error(
            error?.response?.data?.error ||
              "There seems to be a problem. Pease try again later"
          )
        )
        .finally(() => {
          setLoading(false);
          getUserData();
          navigate(-1);
        });
    } else {
      toast.error("Make sure you've filled all fields correctly.");
    }
  };

  const onReady = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      cropperRef.current.cropper.zoomTo(1);
    }
  };

  return (
    <div className={styles.container}>
      <Popup
        isOpen={openPopup}
        linkToEdit={linkToEdit}
        onSave={addLink}
        onClose={() => setOpenPopup(false)}
      />
      <div className={styles.content}>
        <input
          type="file"
          style={{ display: "none" }}
          ref={imgRef}
          accept="image/*"
          onChange={onProfilePicChange}
        />
        <CropperDialog
          isOpen={isCropperDialogOpen}
          setOpen={setCropperDialogOpen}
          maxWidth="600px"
          title={"Crop"}
          // padding="10px 15px"
          persistant
          child={
            <>
              {selectedProfilePic !== null ? (
                <Cropper
                  src={URL.createObjectURL(selectedProfilePic)}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  guides={false}
                  viewMode={1}
                  minCropBoxWidth={100}
                  minCropBoxHeight={100}
                  background={false}
                  responsive={true}
                  checkOrientation={true}
                  ref={cropperRef}
                  onReady={onReady}
                />
              ) : (
                <></>
              )}
            </>
          }
          actions={
            <button className={styles.save} onClick={onCropSubmit}>
              Save
            </button>
          }
        />
        <div
          className={styles.profilePicture}
          onClick={() => imgRef.current.click()}
        >
          <img
            src={displayedProfilePic}
            alt="profile picture"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              pointerEvents: "none",
              objectFit: "cover",
            }}
          />
        </div>
        <div className={styles.userInfo}>
          <TextEditProfile
            type="text"
            value={profile.name}
            onChange={(name) => setProfile({ ...profile, name })}
            placeholder="Name"
            longPlaceholder="Ahmad Alaa"
          />
          <TextEditProfile
            type="email"
            value={user.email}
            placeholder="Email"
            longPlaceholder="your_email@example.com"
            readOnly
            required={false}
          />
          <TextEditProfile
            type="phone"
            value={profile.phone}
            onChange={(phone) => setProfile({ ...profile, phone })}
            placeholder="Phone"
          />
          <TextEditProfile
            select
            value={profile.industry}
            onChange={(industry) => setProfile({ ...profile, industry })}
            options={businessCategories}
            placeholder="Industry"
          />
          <TextEditProfile
            select
            options={countries}
            value={profile.country}
            onChange={(country) => setProfile({ ...profile, country })}
            placeholder="Location"
          />
          <TextEditProfile
            type="text"
            value={profile.company}
            onChange={(company) => setProfile({ ...profile, company })}
            placeholder="Company"
            longPlaceholder="ABC Company"
            required={false}
          />
          <TextEditProfile
            type="text"
            value={profile.bio}
            onChange={(bio) => setProfile({ ...profile, bio })}
            placeholder="About"
            long
            longPlaceholder="Write a brief description about who you are and what you do. (At least 300 characters)"
            length={300}
          />
          {profile?.bio ? (
            <p
              style={{ color: profile?.bio?.length < 300 ? "red" : "green" }}
              className={styles.taCount}
            >
              {profile.bio?.length || 0}/300
            </p>
          ) : (
            <></>
          )}

          <p style={{ marginBottom: 6 }} className={styles.heading}>
            Links
          </p>
          {profile.links &&
            Object.entries(profile.links).map(([key, link]) => (
              <LinkCard
                key={key}
                link={link}
                edit
                onRemove={() => removeLink(key)}
                onEdit={() => {
                  setLinkToEdit({ link, key });
                  setOpenPopup(true);
                }}
              />
            ))}
          <div
            className={styles.linksBtn}
            onClick={() => {
              setLinkToEdit({});
              setOpenPopup(true);
            }}
          >
            <div className={styles.iconContainer}>
              <MdAdd name="add" type="material" size={25} color="#00516A" />
            </div>
            <span>Add Link</span>
          </div>

          <button className={styles.save} onClick={submit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
