import React, { useEffect, useState } from "react";
import styles from "./MembersCard.module.css";
import { useNavigate } from "react-router-dom";
import profile_picture from "../../assets/profile_picture.png";
import { MdVerified } from "react-icons/md";

const MembersCard = ({ member }) => {
  const [imageSrc, setImageSrc] = useState(
    member?.preview?.dummyUrl || profile_picture
  );
  const navigate = useNavigate();

  const nameParts = member?.name?.split(" ");
  const displayName = nameParts.length > 1 ? nameParts[0] + " " + nameParts[nameParts.length - 1] : nameParts[0];

  useEffect(() => {
    if (member?.preview?.imageUrl) {
      const img = new Image();
      img.src = member.preview.imageUrl;
      img.onload = () => setImageSrc(member.preview.imageUrl);
    }
  }, [member]);

  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/members/${member.id}`)}
    >
      <div className={styles.content}>
        <img
          className={styles.img}
          src={imageSrc}
          alt={displayName}
        />
        <div className={styles.txtContainer}>
          <p className={styles.name}>
            {displayName} {member.isVerified ? (
              <MdVerified style={{ color: 'black', fontSize: '16px' }} />
            ) : (
              ""
            )}
          </p>
          <p className={styles.industry}>{member.industry}</p>
        </div>
      </div>
    </div>
  );
};

export default MembersCard;
