import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ViewBlog.module.css";
import { get, post, remove } from "../fetch";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { MdCreate } from "react-icons/md";
import Context from "../Context";
import { toast } from "react-toastify";

const ViewBlog = () => {
  const { id } = useParams();
  const { setPageName, setLoading, user } = useContext(Context);
  const [blog, setBlog] = useState({});
  const [imageSrc, setImageSrc] = useState(blog.preview?.dummyUrl);
  const navigate = useNavigate();

  useEffect(() => {
    setPageName("Blog");
  }, []);

  useEffect(() => {
    setLoading(true);
    get(`blog/${id}`)
      .then((response) => {
        setBlog(response.data);
        setImageSrc(response.data.preview?.dummyUrl);
      })
      .catch(() => {
        toast.error(
          "Sorry! There seems to be a problem. Please come back later"
        );
        setImageSrc(blog.preview?.dummyUrl);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const like = () => {
    setBlog({ ...blog, isLiked: true, likes: blog.likes + 1 });
    post(`blog/${id}/like`).catch(() => {});
  };

  const unlike = () => {
    setBlog({ ...blog, isLiked: false, likes: blog.likes - 1 });
    remove(`blog/${id}/like`).catch(() => {});
  };

  useEffect(() => {
    if (blog?.preview?.imageUrl) {
      const img = new Image();
      img.src = blog.preview.imageUrl;
      img.onload = () => setImageSrc(blog.preview.imageUrl);
    }
  }, [blog]);

  return (
    <div className={styles.container}>
      <div className={styles.pageContent}>
        <img src={imageSrc} className={styles.image} alt={blog.title} />
        <div className={styles.categoriesContainer}>
          <div className={styles.category}>
            {blog.isLiked ? (
              <IoHeart size={16} color="#E02A2A" />
            ) : (
              <IoHeartOutline size={16} color="#E02A2A" />
            )}
            <span style={{ marginLeft: 2, fontWeight: "500", fontSize: 14 }}>
              {blog.likes || 0}
            </span>
          </div>
          {blog.topics &&
            blog.topics.map((topic, index) => (
              <div key={index} className={styles.category}>
                <span>{topic}</span>
              </div>
            ))}
        </div>
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{blog.title}</h1>
          <p className={styles.date}>{blog.created}</p>
          <p className={styles.content}>
            {blog.content?.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
        <button
          onClick={blog.isLiked ? unlike : like}
          className={styles.likeBtn}
        >
          {blog.isLiked ? (
            <IoHeart size={30} color="#E02A2A" />
          ) : (
            <IoHeartOutline size={30} color="#E02A2A" />
          )}
          <span style={{ marginLeft: 2, fontWeight: "500", fontSize: 18 }}>
            {blog.likes || 0}
          </span>
        </button>
        <p>Like this blog if you like it</p>
        {(user.type === "admin" || (user.role === "editor" && blog?.writer === user?.uid)) && (
          <button
            className={styles.postBtn}
            onClick={() => navigate(`/post-blog/${id}`)}
          >
            <MdCreate color="white" size={25} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewBlog;
