import React, { useContext, useEffect, useState } from "react";
import styles from "./ResetPassword.module.css";
import cube from "../../assets/cube-whitebg.png";
import password from "../../assets/Auth/password.svg";
import AuthText from "../../Components/TextComponents/AuthText";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../fetch";
import Context from "../../Context";
import { toast } from "react-toastify";

const ResetPassword = ({ newUser = false, forget = false }) => {
  const { setLoading, setPageName, token, isAuthenticated } =
    useContext(Context);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const verToken = query.get("token");
  const navigate = useNavigate();
  useEffect(() => setPageName(newUser ? "Welcome" : "Change Password"), []);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (verToken) {
      setLoading(true);
      post("auth/verifyResetCode", { token: verToken })
        .catch(() => setValid(false))
        .finally(() => setLoading(false));
    } else if (!isAuthenticated) {
      setValid(false);
    }
  }, [location, newUser, navigate]);

  const [data, setData] = useState({
    password: {
      text: "",
      error: false,
    },
    password_confirmation: {
      text: "",
      error: false,
    },
  });

  const setPassword = (password) => {
    setData({
      ...data,
      password: {
        text: password,
        error: false,
      },
    });
  };

  const setPasswordRepeat = (password) => {
    setData({
      ...data,
      password_confirmation: {
        text: password,
        error: false,
      },
    });
  };

  const submit = (e) => {
    e.preventDefault();
    if (data.password.text.length < 8) {
      setData({
        ...data,
        password: {
          text: "",
          error: true,
        },
      });
    } else if (data.password_confirmation.text !== data.password.text) {
      setData({
        ...data,
        password_confirmation: {
          text: "",
          error: true,
        },
      });
    } else {
      setLoading(true);
      post("auth/resetPassword", {
        password: data.password.text,
        token: verToken || token,
      })
        .then(() => {
          toast.success(
            "Password changed successfully. You can login with your new password now."
          );
          setLoading(false);
          navigate("/");
        })
        .catch(() => {
          setLoading(false);
          toast.error("An error has occured. Please try again later");
        });
    }
  };

  return (
    <div className={styles.container}>
      {valid ? (
        <div
          className={`${styles.content} ${
            !isAuthenticated ? styles.centered : ""
          }`}
        >
          <img className={styles.cube} src={cube} alt="cube" />
          {/* <h2 className={styles.title}>
          {newUser ? "Welcome!" : "Change Password"}
        </h2> */}
          <form onSubmit={submit}>
            <AuthText
              type="password"
              placeholder="New password"
              value={data.password.text}
              error={data.password.error}
              onChange={(e) => setPassword(e.target.value)}
              img={password}
            />
            {data.password.error ? (
              <span className={styles.error}>
                Password must be at least 8 characters.
              </span>
            ) : (
              ""
            )}
            <AuthText
              type="password"
              placeholder="Confirm new password"
              value={data.password_confirmation.text}
              error={data.password_confirmation.error}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              img={password}
            />
            {data.password_confirmation.error ? (
              <span className={styles.error}>Passwords do not match.</span>
            ) : (
              ""
            )}
            <button type="submit" className={styles.loginBtn} onClick={submit}>
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className={`${styles.content} ${styles.centered}`}>
          <img className={styles.cube} src={cube} alt="cube" />
          <h2 className={styles.invalidTitle}>Sorry, this link is invalid</h2>
          <p className={styles.p}>
            The link is invalid or has expired. Please request a new link.
          </p>
          <button
            onClick={() => navigate("/forget-password")}
            className={styles.link}
          >
            New link
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
