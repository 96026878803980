import React, { useEffect, useContext, useState } from "react";
import styles from "./NotificationsPage.module.css";
import Context from "../../Context";
import NotificationCard from "../../Components/Notifications/NotificationCard";
import { get, post } from "../../fetch";
import { toast } from "react-toastify";
import { MdMarkEmailRead } from "react-icons/md";

const NotificationsPage = () => {
  const { setLoading, setPageName } = useContext(Context);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setPageName("Notifications");
    setLoading(true);
    get("notification")
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        console.log(updatedResponse);
        setNotifications(updatedResponse);
      })
      .catch(() =>
        toast.error(
          "Sorry!",
          "There seems to be a problem. Please come back later."
        )
      )
      .finally(() => setLoading(false));
  }, []);

  const read = (id) => {
    post(`notification/read/${id}`)
    const newNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        return { ...notification, isRead: true };
      }
      return notification;
    });

    setNotifications(newNotifications);
  };

  const readAll = () => {
    post("notification/readAll");
    const newNotifications = notifications.map((notification) => {
      notification.isRead = true;
      return notification;
    });
    setNotifications(newNotifications);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {notifications.length ? (
          notifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              read={read}
            />
          ))
        ) : (
          <p className={styles.description}>You have no notifications yet.</p>
        )}
      </div>
      {notifications.length ? (
        <button className={styles.postBtn} onClick={readAll}>
          <MdMarkEmailRead color="white" size={25} />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotificationsPage;
