import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PlansOverviewCard.module.css";
import { IoStar, IoCheckmark } from "react-icons/io5";

export default function PlansOverviewCard({ pack = {} }) {
  const navigate = useNavigate();

  return (
    <div className={styles.cardGradient}>
      <div className={styles.cardHeader}>
        {pack.isFavourite && <IoStar color="gold" />}
        <h3 className={styles.name}>{pack.name || ""}</h3>
      </div>
      <div className={styles.card}>
        <div style={{ width: "100%" }}>
          <p className={styles.price}>JD {pack.price || ""}</p>
          <p className={styles.length}>{pack.duration || ""} Months</p>
          <div className={styles.sep} />
          {pack.features?.map((bullet, index) => (
            <div className={styles.bullet} key={index}>
              <IoCheckmark size={15} color="#237695" />
              <span className={styles.bulletTxt}>{bullet}</span>
            </div>
          ))}
        </div>
        <button
          className={styles.btn}
          onClick={() => navigate(`/payment/${pack.id}`)}
        >
          Go {pack.name}
        </button>
      </div>
    </div>
  );
}
