import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IoHomeOutline,
  IoHome,
  IoPersonOutline,
  IoPerson,
  IoNotificationsOutline,
  IoNotifications,
  IoPersonCircleOutline,
  IoPersonCircle,
  IoLogOutOutline,
} from "react-icons/io5";
import styles from "./BottomNav.module.css";
import Context from "../Context";

export default function BottomNav() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(Context);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate("/");
    if (newValue === 1) navigate("/members");
    if (newValue === 2) navigate("/notifications");
    if (newValue === 3) navigate("/account");
  };

  useEffect(() => {
    const basePath = location.pathname.split("/")[1];
    switch (basePath) {
      case "":
        setValue(0);
        break;
      case "members":
        setValue(1);
        break;
      case "notifications":
        setValue(2);
        break;
      case "account":
        setValue(3);
        break;
      default:
        setValue(0);
    }
  }, [location.pathname]);

  return (
    <div className={styles.bottomNavContainer}>
      <div className={styles.navItem} onClick={() => handleChange(0)}>
        {value !== 0 ? (
          <IoHomeOutline size={30} color="#000" />
        ) : (
          <IoHome size={30} color="#000" />
        )}
      </div>
      {((user.expiry && user?.expiry - Date.now() > 0) || user?.type !== "member") ? (
        <>
          <div className={styles.navItem} onClick={() => handleChange(1)}>
            {value !== 1 ? (
              <IoPersonOutline size={30} color="#000" />
            ) : (
              <IoPerson size={30} color="#000" />
            )}
          </div>
          <div className={styles.navItem} onClick={() => handleChange(2)}>
            {value !== 2 ? (
              <IoNotificationsOutline size={30} color="#000" />
            ) : (
              <IoNotifications size={30} color="#000" />
            )}
          </div>
          <div className={styles.navItem} onClick={() => handleChange(3)}>
            {value !== 3 ? (
              <IoPersonCircleOutline size={32} color="#000" />
            ) : (
              <IoPersonCircle size={32} color="#000" />
            )}
          </div>
        </>
      ) : (
        <div className={styles.navItem} onClick={() => logout()}>
          <IoLogOutOutline size={30} color="#000" />
        </div>
      )}
    </div>
  );
}
