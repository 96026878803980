import React, { useEffect, useState } from "react";
import Context from "./Context";
import { get, post, setAuthorizationToken } from "./fetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Provider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pageName, setPageName] = useState("");

  const logout = async () => {
    setLoading(true);
    try {
      localStorage.clear();
      setToken("");
      setUser({});
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error clearing localStorage:", error);
    } finally {
      setLoading(false);
      navigate("/");
    }
  };

  const getUserData = async () => {
    get(`user/${user.uid}`)
      .then((response) => {
        setUser({ ...user, ...response.data });
      })
      .catch(() => logout())
      .finally(() => setLoading(false));
  };

  const authCheck = async () => {
    const t = localStorage.getItem("token");
    if (t) {
      setAuthorizationToken(t);
      get("auth/session")
        .then((response) => {
          setUser(response.data.userData);
          setIsAuthenticated(true);
          setToken(response.data.token);
          setAuthorizationToken(response.data.token);
          localStorage.setItem("token", response.data.token);
        })
        .catch((error) => {
          console.log("error", error);
          logout();
        })
        .finally(() => setLoading(false));
    } else {
      setIsAuthenticated(false);
    }
    if (!isAuthenticated) setLoading(false);
  };

  useEffect(() => {
    authCheck();
  }, []);

  const auth = async (data) => {
    setLoading(true);
    try {
      const response = await post("auth/login", data);
      setToken(response.token);
      localStorage.setItem("token", response.token);
      setAuthorizationToken(response.token);
      setUser(response.userData);
      setIsAuthenticated(true);
    } catch (error) {
      toast.error(
        "Sorry! Make sure you've entered the correct email and password."
      );
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Context.Provider
      value={{
        token,
        user,
        auth,
        logout,
        loading,
        setLoading,
        isAuthenticated,
        getUserData,
        pageName,
        setPageName,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
