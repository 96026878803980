import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import styles from "./FeaturedBlog.module.css";

const FeaturedBlog = ({ blog }) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(blog?.preview?.dummyUrl); // Start with dummyUrl

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|windows phone/i.test(
      userAgent.toLowerCase()
    );
  };

  const handleNavigate = () => {
    navigate(`/blog/${blog.id}`);
  };

  useEffect(() => {
    if (blog?.preview?.imageUrl) {
      const img = new Image();
      img.src = blog.preview.imageUrl;
      img.onload = () => setImageSrc(blog.preview.imageUrl); // Set to imageUrl after loading
    }
  }, [blog?.preview?.imageUrl]);

  return (
    <div className={styles.card} onClick={handleNavigate}>
      <img src={imageSrc} alt="blog" className={styles.image} />
      <div className={styles.categoriesContainer}>
        <div className={styles.category}>
          {blog.isLiked ? (
            <IoHeart size={16} color="#E02A2A" />
          ) : (
            <IoHeartOutline size={16} color="#E02A2A" />
          )}
          <span>{blog.likes || "0"}</span>
        </div>
        {isMobileDevice() && blog.topics && (
          <>
            <div className={styles.category}>
              <span>{blog.topics[0]}</span>
            </div>
            {blog.topics.length - 1 > 0 && <div className={styles.category}>
              <span>+{blog.topics.length - 1} more</span>
            </div>}
          </>
        )}
        {!isMobileDevice() && blog.topics &&
          blog.topics.map((topic, index) => (
            <div key={index} className={styles.category}>
              <span>{topic}</span>
            </div>
          ))}
      </div>
      <h2 className={styles.title}>{blog.title}</h2>
      <p className={styles.date}>{blog.created}</p>
      <p className={styles.content}>{blog.content}</p>
    </div>
  );
};

export default FeaturedBlog;
