import React, { useEffect, useState } from "react";
import styles from "./Popup.module.css";
import TextEditProfile from "./TextComponents/TextEditProfile";
import { toast } from "react-toastify";

const Popup = ({ isOpen, onClose, onSave, linkToEdit }) => {
  const [link, setLink] = useState({});
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      setIsAnimating(true);
    } else if (!isOpen && isAnimating) {
      setIsAnimating(false);
      setTimeout(() => {
        document.body.style.overflow = "auto";
      }, 100);
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    setLink(linkToEdit.link || { title: "", url: "" });
  }, [linkToEdit]);

  function cleanURL(url) {
    return url.replace(/(^\w+:|^)\/\//, "").replace(/^www\./, "");
  }

  const isValidUrl = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name
        "localhost|" + // localhost
        "\\d{1,3}(\\.\\d{1,3}){3})" + // OR IPv4
        "(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*" + // port and path
        "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z0-9_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(url);
  };
  
  const handleSubmit = () => {
    if (!link.title) {
      toast.error("Please enter a name for the link.");
      return;
    }

    if (!isValidUrl(link.url)) {
      toast.error("Please enter a valid URL.");
      return;
    }

    onSave({ ...link, url: cleanURL(link.url) }, linkToEdit.key);
    onClose();
  };

  const handleClose = () => {
    setIsAnimating(false);
    setTimeout(() => {
      onClose();
    }, 100);
  };
  

  if (!isOpen && !isAnimating) return null;

  return (
    <div className={`${styles.overlay} ${isAnimating ? styles.fadeIn : styles.fadeOut}`}>
      <div className={`${styles.popup} ${isAnimating ? styles.scaleIn : styles.scaleOut}`}>
        <h2 className={styles.header}>Add link</h2>
        <div className={styles.sep} />
        <TextEditProfile
          value={link.title}
          onChange={(title) => setLink({ ...link, title })}
          placeholder="Name"
          longPlaceholder="My website"
        />
        <TextEditProfile
          value={link.url}
          onChange={(url) => setLink({ ...link, url })}
          placeholder="Url"
          longPlaceholder="example.com"
          type="url"
        />
        <div className={styles.buttonContainer}>
          <button
            onClick={handleClose}
            className={`${styles.button} ${styles.cancelButton}`}
          >
            Cancel
          </button>

          <button
            onClick={handleSubmit}
            className={`${styles.button} ${styles.saveButton}`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
