import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import styles from "./BlogCard.module.css";

const BlogCard = ({ blog, showStatus = false }) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(blog?.preview?.dummyUrl);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|windows phone/i.test(
      userAgent.toLowerCase()
    );
  };

  const handleNavigate = () => {
    if (blog?.id) navigate(`/blog/${blog.id}`);
  };

  useEffect(() => {
    if (blog?.preview?.imageUrl) {
      const img = new Image();
      img.src = blog.preview.imageUrl;
      img.onload = () => setImageSrc(blog.preview.imageUrl);
    }
  }, [blog]);

  const displayedTopic = blog?.topics
    ?.reduce((a, b) => (a.length <= b.length ? a : b))
    .replace(/^./, (char) => char.toUpperCase());

  return (
    <div
      style={{ boxShadow: !blog && "none" }}
      className={`${styles.card} ${!blog && styles.pulse}`}
      onClick={handleNavigate}
    >
      <div className={styles.textContainer}>
        <p
          style={{ maxWidth: !blog ? 100 : "unset" }}
          className={`${styles.date} ${!blog && styles.pulse}`}
        >
          {blog?.created || ""}
        </p>
        <h3
          style={{ maxWidth: !blog ? 150 : "unset" }}
          className={`${styles.title} ${!blog && styles.pulse}`}
        >
          {blog?.title || ""}
        </h3>
        <p
          style={{ maxWidth: !blog ? 200 : "unset" }}
          className={`${styles.content} ${!blog && styles.pulse}`}
        >
          {blog?.content || ""}
          {blog?.content ? "..." : ""}
        </p>
        {blog ? (
          <>
            <div className={styles.categoriesContainer}>
              {!isMobileDevice() &&
                blog?.topics.slice(0, 2).map((topic, index) => (
                  <div key={index} className={styles.category}>
                    <span>{topic}</span>
                  </div>
                ))}
              {isMobileDevice() && (
                <>
                  <div className={styles.category}>
                    <span>
                      {blog?.topics?.length - 1 > 0 && displayedTopic.length > 12
                        ? `${displayedTopic.slice(0, 12)}...`
                        : displayedTopic}
                    </span>
                  </div>
                  {blog?.topics?.length > 1 && (
                    <div className={styles.category}>
                      <span>+{blog?.topics?.length - 1}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.likeContainer}>
              <div className={styles.category}>
                {blog?.isLiked ? (
                  <IoHeart size={16} style={{ marginRight: 3 }} color="#E02A2A" />
                ) : (
                  <IoHeartOutline size={16} style={{ marginRight: 3 }} color="#E02A2A" />
                )}
                <span>{blog?.likes || 0}</span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {blog ? (
        <img src={imageSrc} alt="blog" className={styles.image} />
      ) : (
        <div className={`${styles.image} ${styles.skeleton}`} />
      )}
      {showStatus ? (
        <div
          className={styles.status}
          style={{
            backgroundColor:
              blog?.status?.toLowerCase() === "published"
                ? "#00C853"
                : "#E02A2A",
          }}
        >
          {blog.status.toUpperCase()}
        </div>
      ) : null}
    </div>
  );
};

export default BlogCard;
