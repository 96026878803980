import React from "react";
import styles from "./AuthText.module.css";
import { IoMail, IoLockClosed } from 'react-icons/io5';

const AuthText = ({ placeholder, value, onChange, img, type, error }) => {
    return (
        <div className={`${styles.container} ${error ? styles.error : ""}`}>
            {type === "password" ? <IoLockClosed size={22} style={{ margin: 10 }} /> : <IoMail size={22} style={{ margin: 10 }} />}
            <input placeholder={placeholder} type={type} value={value} onChange={onChange} />
        </div>
    );
}

export default AuthText;