import axios from "axios";
import { post } from "../fetch";
import imageCompression from "browser-image-compression";

export const uploadFile = async (file, fileName, storagePath) => {
  try {
    const uploadUrl = await post("file/uploadLink", {
      fileName,
      storagePath,
      contentType: file.type,
    });

    const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': file.type },
    });

    if (!uploadResponse.ok) {
      throw new Error("Failed to upload file");
    }
    return fileName;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const uploadImage = async (image, storagePath, isLarge = false) => {
  if (!image || !storagePath) {
    console.log("uploadImage skipped");
    return {};
  }
  try {
    const imageOptions = {
      maxSizeMB: isLarge ? 0.5 : 0.25,
      maxWidthOrHeight: isLarge ? 1080 : 480,
      useWebWorker: true,
    };
    const dummyOptions = {
      maxSizeMB: 0.001,
      maxWidthOrHeight: 15,
      useWebWorker: true,
    };
    const dummyImage = await imageCompression(image, dummyOptions);
    const optimizedImage = await imageCompression(image, imageOptions);

    console.log("dummyImage", dummyImage, "optimizedImage", optimizedImage);

    const imageId = `image-${Date.now()}`;
    const dummyId = `dummy-${Date.now()}`;

    await uploadFile(optimizedImage, imageId, storagePath);
    await uploadFile(dummyImage, dummyId, storagePath);

    return { imageId, dummyId };
  } catch (error) {
    console.error("Error updating image:", error);
    throw error;
  }
};
