import React, { useContext, useEffect, useState } from "react";
import styles from "./Application.module.css";
import Container from "../../Components/Account/Container";
import cube from "../../assets/cube-whitebg.png";
import { useNavigate } from "react-router-dom";

import ReactPhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

import page1 from "../../assets/Dashboard/page1.svg";
import page2 from "../../assets/Dashboard/page2.svg";
import page3 from "../../assets/Dashboard/page3.svg";

import { post } from "../../fetch";
import Context from "../../Context";
import { countriesArr } from "./countries";
import Select from "react-select";
import TextEditProfile from "../../Components/TextComponents/TextEditProfile";

import { toast } from "react-toastify";

const Application = () => {
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const { setLoading, setPageName } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    name: "",
    email: "",
    emailConfirmation: "",
    phone: "",
    country: "Jordan",
    url: "",
    yearsOfExperience: "",
    businessOutline: "",

    educationalBackground: "",
    professionalAffiliation: "",
    strengths: "",
    reasonsToJoin: "",

    referredBy: "",
  });

  useEffect(() => {
    if (agree) setTermsError(false);
  }, [agree]);

  useEffect(() => {
    setPageName("Apply")
    const countryData = countriesArr.map((country) => country.name.common);
    setCountries(countryData.sort((a, b) => a.localeCompare(b)));
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleError = (field, message) => {
    setErrors(prev => ({ ...prev, [field]: message }));
  };

  const handleValid = (field) => {
    setErrors(prev => {
      const updatedErrors = { ...prev };
      delete updatedErrors[field];
      return updatedErrors;
    });
  };

  const cleanURL = (url) => {
    let cleanedURL = url.replace(/(^\w+:|^)\/\//, "").replace("www.", "");
    cleanedURL = cleanedURL.replace(/\/+$/, "");
    return cleanedURL;
  };

  const back = () => {
    if (page === 1) {
      navigate("/");
    } else {
      setPage(page - 1);
      scrollToTop();
    }
  };
  const next = (event) => {
    event.preventDefault();
    if (page === 1) {
      if (
        data.name !== "" &&
        data.email !== "" &&
        data.phone !== "" &&
        data.country !== "" &&
        data.yearsOfExperience !== "" &&
        data.businessOutline.length >= 300 &&
        Object.keys(errors).length === 0
      ) {
        setPage(2);
        scrollToTop();
      } else {
        toast.error(
          "Make sure you've filled all fields correctly"
        );
      }
    } else if (page === 2) {
      if (
        data.educationalBackground !== "" &&
        data.strengths.length >= 150 &&
        data.reasonsToJoin.length >= 150 &&
        Object.keys(errors).length === 0
      ) {
        setPage(3);
        scrollToTop();
      } else {
        toast.error(
          "Make sure you've filled all fields correctly"
        );
      }
    } else {
      if (agree) {
        setLoading(true);
        post("application", { ...data, url: cleanURL(data.url) })
          .then(() => {
            setLoading(false);
            toast.success(
              "Thank you for applying. Your application will be reviewd soon, you will recieve an email when you get accepted."
            );
            navigate("/");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.error || "There seems to be a problem. Pease try again later");
          });
      } else {
        toast.error(
          "Make sure you've agreed to the terms & conditions"
        );
        setTermsError(true);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.cube} src={cube} alt="cube" />

        {page === 1 && <img className={styles.pages} src={page1} alt="" />}
        {page === 2 && <img className={styles.pages} src={page2} alt="" />}
        {page === 3 && <img className={styles.pages} src={page3} alt="" />}

        {page === 1 && (
          <>
            <p className={styles.section}>*Personal Information:</p>
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.name}
              onChange={(name) => setData({ ...data, name })}
              placeholder="Name"
              longPlaceholder="Ahmad Alaa"
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="email"
              value={data.email}
              onChange={(email) => setData({ ...data, email })}
              placeholder="Email"
              longPlaceholder="your_email@example.com"
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="phone"
              value={data.phone}
              onChange={(phone) => setData({ ...data, phone })}
              placeholder="Phone"
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              select
              options={countries}
              value={data.country}
              onChange={(country) => setData({ ...data, country })}
              placeholder="Location"
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="url"
              value={data.url}
              onChange={(url) => setData({ ...data, url })}
              placeholder="Website or Social Media"
              longPlaceholder="example.com"
            />

            <p className={styles.section}>*Entrepreneurial Background:</p>
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="numeric"
              value={data.yearsOfExperience}
              onChange={(yearsOfExperience) =>
                setData({ ...data, yearsOfExperience })
              }
              placeholder="Years of entrepreneurial experience"
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.businessOutline}
              long
              longPlaceholder="At least 300 characters"
              length={300}
              onChange={(businessOutline) =>
                setData({ ...data, businessOutline })
              }
              placeholder="Tell us more about your business"
            />
            {data.businessOutline.length ? (
              <p
                style={{
                  color: data.businessOutline.length < 300 ? "red" : "green",
                }}
                className={styles.textAreaLength}
              >
                {data.businessOutline.length}/300
              </p>
            ) : (
              ""
            )}
          </>
        )}

        {page === 2 && (
          <>
            <p className={styles.section}>*Qualifications and Achievements:</p>
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.educationalBackground}
              onChange={(educationalBackground) =>
                setData({ ...data, educationalBackground })
              }
              placeholder="Educational Background"
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.professionalAffiliation}
              onChange={(professionalAffiliation) =>
                setData({ ...data, professionalAffiliation })
              }
              placeholder="Professional Affiliations (Optional)"
              required={false}
            />
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.strengths}
              onChange={(strengths) => setData({ ...data, strengths })}
              placeholder="Mention three strengths you have"
              long
              longPlaceholder="At least 150 characters"
              length={150}
            />
            {data.strengths.length ? (
              <p
                style={{ color: data.strengths.length < 150 ? "red" : "green" }}
                className={styles.textAreaLength}
              >
                {data.strengths.length}/150
              </p>
            ) : (
              ""
            )}
            <p className={styles.section}>*Commitment to the Community:</p>
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.reasonsToJoin}
              onChange={(reasonsToJoin) => setData({ ...data, reasonsToJoin })}
              placeholder="Why do you want to join?"
              long
              longPlaceholder="At least 150 characters"
              length={150}
            />
            {data.reasonsToJoin.length ? (
              <p
                style={{
                  color: data.reasonsToJoin.length < 150 ? "red" : "green",
                }}
                className={styles.textAreaLength}
              >
                {data.reasonsToJoin.length}/150
              </p>
            ) : (
              ""
            )}
          </>
        )}

        {page === 3 && (
          <>
            <p className={styles.section}>Additional Information:</p>
            <TextEditProfile 
              onError={handleError}
              onValid={handleValid}
              type="text"
              value={data.referredBy}
              onChange={(referredBy) => setData({ ...data, referredBy })}
              placeholder="Referral Code"
              required={false}
            />
            <span
              style={{
                color: "black",
                textAlign: "left",
                marginTop: -5,
                width: "90%",
              }}
            >
              If you have a friend who's already a member, obtain free months
              for you and them by asking them for the referral code found in
              their personal profile.
            </span>
            <div className={styles.tCContainer} style={{ color: termsError ? "red" : "black" }}>
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={agree}
                onClick={() => setAgree(!agree)}
              />
              <p className={styles.terms}>
                I agree to the{" "}
                <button
                  style={{ color: termsError ? "red" : "black" }}
                  onClick={() => {
                    const newTab = window.open(
                      "/terms-and-conditions",
                      "_blank"
                    );
                    newTab.focus();
                  }}
                >
                  Term & Conditions
                </button>
              </p>
            </div>
          </>
        )}

        {page !== 3 ? (
          <div className={styles.btnsContainer}>
            <button className={`${styles.btn} ${styles.back}`} onClick={back}>
              {page === 1 ? "Cancel" : "Back"}
            </button>
            <button className={`${styles.btn} ${styles.next}`} onClick={next}>
              Next
            </button>
          </div>
        ) : (
          <div className={styles.btnsContainer}>
            <button className={`${styles.btn} ${styles.back}`} onClick={back}>
              {page === 1 ? "Cancel" : "Back"}
            </button>
            <button className={`${styles.btn} ${styles.next}`} onClick={next}>
              {page === 3 ? "Apply" : "Next"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Application;
