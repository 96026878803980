import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SubscriptionPlanCard.module.css";
import Context from "../../Context";

export default function SubscriptionPlanCard({ payment = null }) {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const isExpiryLessThan30DaysAway = () => {
    const currentDate = new Date();
    const expiryDate = new Date(user.expiry);

    const thirtyDaysFromNow = new Date(currentDate);
    thirtyDaysFromNow.setDate(currentDate.getDate() + 30);

    return expiryDate < thirtyDaysFromNow;
  };
console.log(payment)
  return (
    <div className={styles.card}>
      {payment?.package?.name ? (
        <h2 className={styles.cardTitle}>{payment?.package?.name}{payment.package.name.toLowerCase() !== "exclusive reward" && " Plan"}</h2>
      ) : (
        <h2 className={styles.cardTitle}>No Active Plan</h2>
      )}
      {payment?.expiryDate && (
        <p className={styles.expirationDate}>End date: {payment?.expiryDate}</p>
      )}
      {payment?.status.toLowerCase() === "pending" && (
        <p className={styles.expirationDate}>
          Your last payment is being reviewed
        </p>
      )}
      {/* {((isExpiryLessThan30DaysAway() &&
        payment?.status?.toLowerCase() !== "pending") || !payment)  && ( */}
          <button
            className={styles.btn}
            onClick={() => navigate("/account/membership/membership-plans")}
          >
            {payment ? "Extend Membership" : "Become a Member"}
          </button>
        {/* )} */}
    </div>
  );
}
